import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import CreateButton from '../buttons/CreateButton';
import SearchInput from '../inputs/SearchInput';
import TableLoadingOverlay from './TableLoadingOverlay';
import StandardRow from './StandardRow';
import { strings } from '../../../utils/strings';
import { spacing } from '../../../themes/constants';
import { UserContext } from '../../../contexts';

// based on: https://codesandbox.io/s/chakra-ui-data-table-vfivp?file=/src/DataTable.tsx:1495-1917

export type StandardRowData = {
  id: string;
  hideEdit?: boolean;
  hideDelete?: boolean;
} & ({ createdAt: Date } | { order: number });

const columnVisibility = { id: false, createdAt: false, order: false };

type StandardTableProps<Data extends StandardRowData> = {
  title: string;
  isLoading?: boolean;
  data: Data[];
  columns: ColumnDef<Data, any>[];
  canSearch?: boolean;
  onEditRow?: (id: string) => void;
  onDeleteRow?: (id: string) => void;
  onCancelDeleteRow?: (id: string) => void;
  cancelDeleteProperty?: keyof Data;
  deleteText?: 'Delete' | 'Remove';
  onCreate?: () => void;
  createButtonTitle?: string;
  boldColumn?: keyof Data;
  defaultSortColumn?: 'createdAt' | 'order';
  unsortableColumns?: string[];
  withOuterBorder?: boolean;
  withoutMenuCol?: boolean;
  maxHeight?: string;
  marginTop?: string;
};

// note: currently no support for numeric data
export default function StandardTable<Data extends StandardRowData>({
  title,
  isLoading = false,
  data,
  columns,
  canSearch = true,
  onEditRow,
  onDeleteRow,
  deleteText = 'Delete',
  onCreate,
  createButtonTitle,
  boldColumn,
  defaultSortColumn = 'createdAt',
  onCancelDeleteRow,
  cancelDeleteProperty,
  // there may be a better way using built-in react-table functionality?
  unsortableColumns = [],
  withOuterBorder = false,
  withoutMenuCol = false,
  maxHeight = '560px',
  marginTop,
}: StandardTableProps<Data>) {
  const { loggedInUser } = useContext(UserContext);
  // initially sort by most recently added
  const [sorting, setSorting] = useState<SortingState>([
    { id: defaultSortColumn, desc: defaultSortColumn === 'createdAt' },
  ]);
  const [globalFilter, setGlobalFilter] = useState('');

  // if no sorting present, resort to defaultSortColumn
  useEffect(() => {
    if (sorting.length === 0) {
      setSorting([
        { id: defaultSortColumn, desc: defaultSortColumn === 'createdAt' },
      ]);
    }
  }, [defaultSortColumn, sorting.length]);

  // use existing @tanstack/match-sorter-utils fuzzy filter example
  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Don't search hidden columns
    if (Object.keys(columnVisibility).includes(columnId)) return false;
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);
    // Store the itemRank info
    addMeta({ itemRank });
    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnVisibility,
      sorting,
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const noFilterResults =
    !!globalFilter && table.getRowModel().rows.length === 0;

  return (
    <Box position="relative">
      {isLoading && <TableLoadingOverlay />}
      <TableContainer
        backgroundColor="neutrals.white"
        borderRadius="10px"
        marginTop={marginTop}
        border={withOuterBorder ? '1px solid' : 'initial'}
        borderColor={withOuterBorder ? 'neutrals.brandGrey.100' : 'initial'}
      >
        <Box layerStyle="tableTitle">
          <VStack align="left" spacing={spacing[25]}>
            <Text
              as="h2"
              textStyle="h2"
              textColor="neutrals.navigationOutline"
              data-testid="table-title"
            >
              {title}
            </Text>
            {canSearch && (
              <SearchInput
                setSanitizedValue={setGlobalFilter}
                isDisabled={isLoading}
              />
            )}
          </VStack>
          {createButtonTitle && onCreate && (
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <CreateButton
                label={createButtonTitle}
                onClick={onCreate}
                isDisabled={isLoading}
              />
            </Box>
          )}
        </Box>
        <Box overflow="auto" maxHeight={maxHeight}>
          <Table variant="flowTable">
            <Thead>
              <Tr>
                {table.getHeaderGroups()[0].headers.map((header) => {
                  const isSortable = !unsortableColumns.includes(header.id);
                  return (
                    <Th
                      key={header.id}
                      onClick={
                        isSortable
                          ? header.column.getToggleSortingHandler()
                          : undefined
                      }
                      _hover={{ cursor: isSortable ? 'pointer' : 'default' }}
                    >
                      <HStack spacing={spacing[20]}>
                        <Text textStyle="subtitle4">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </Text>
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === 'desc' ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : (
                          // to ensure the header has a constant width
                          <TriangleUpIcon style={{ visibility: 'hidden' }} />
                        )}
                      </HStack>
                    </Th>
                  );
                })}
                {/* options menu column - no header content */}
                {!withoutMenuCol && <Th />}
              </Tr>
            </Thead>

            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <StandardRow<Data>
                  key={row.id}
                  row={row}
                  onEdit={onEditRow && (() => onEditRow(row.original.id))}
                  onDelete={onDeleteRow && (() => onDeleteRow(row.original.id))}
                  deleteText={deleteText}
                  boldColumn={boldColumn}
                  isDisabled={isLoading}
                  withoutMenuCell={withoutMenuCol}
                  onCancelDelete={
                    onCancelDeleteRow &&
                    (() => onCancelDeleteRow(row.original.id))
                  }
                  hasCancelRequest={
                    row.original[cancelDeleteProperty] === loggedInUser.email
                  }
                />
              ))}
            </Tbody>
          </Table>
        </Box>
        {noFilterResults && !isLoading && (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            padding={20}
          >
            <Text textStyle="h5" color={'neutrals.brandGrey.500'}>
              {strings.common.noResults}
            </Text>
          </Box>
        )}
      </TableContainer>
    </Box>
  );
}
