import React, { Fragment } from 'react';
import { ImageLayout, ScreenType } from '../../../gql/gqlRequests';
import { ScreenContextsInput } from '../../../types/screens';
import RadioGroup, { Radio } from '../../common/inputs/RadioGroup';
import NumberInput from '../../common/inputs/NumberInput';
import {
  BackgroundImageInput,
  IconInput,
  PrimaryBackgroundImageInput,
  SecondaryBackgroundImageInput,
} from './ScreenContextInputs';
import Toggle from '../../common/inputs/Toggle';
import { strings } from '../../../utils/strings';
import { RequestError } from '../../../types';
import { hasErrorForKey } from '../../../utils/errors';
import { listRequiredScreenContexts } from '../../../utils/screens';

const { screenContexts } = strings;

export type ScreenContextFormProps = {
  screenType: ScreenType;
  value: ScreenContextsInput;
  partiallyUpdate: (value: Partial<ScreenContextsInput>) => void;
  error: RequestError | null;
  isValidationActive: boolean;
  isScreenFormLoading: boolean;
};

// shared forms

export function SharedBackgroundImageScreenContextForm({
  screenType,
  value,
  partiallyUpdate,
  isValidationActive,
  error,
  isScreenFormLoading,
}: ScreenContextFormProps) {
  const isInvalid =
    hasErrorForKey(error, 'Image') ||
    (listRequiredScreenContexts(screenType).includes('backgroundImage') &&
      !value.backgroundImage);
  return (
    <BackgroundImageInput
      // key required to force re-render when screen type is changed to clear inside state of the component
      key={screenType}
      value={value.backgroundImage}
      onChange={(imageKey) => partiallyUpdate({ backgroundImage: imageKey })}
      isInvalid={isValidationActive && isInvalid}
      isDisabled={isScreenFormLoading}
    />
  );
}

export function WelcomeScreenContextForm({
  screenType,
  value,
  partiallyUpdate,
  isValidationActive,
  error,
  isScreenFormLoading,
}: ScreenContextFormProps) {
  const isInvalid =
    hasErrorForKey(error, 'Image') ||
    (listRequiredScreenContexts(screenType).includes('backgroundImage') &&
      !value.backgroundImage);
  return (
    <Fragment>
      <Toggle
        label={screenContexts.alwaysShowOnLaunch}
        value={!!value.alwaysShowOnLaunch}
        setValue={(alwaysShowOnLaunch) =>
          partiallyUpdate({ alwaysShowOnLaunch })
        }
        isDisabled={isScreenFormLoading}
      />
      <BackgroundImageInput
        value={value.backgroundImage}
        onChange={(imageKey) => partiallyUpdate({ backgroundImage: imageKey })}
        isInvalid={isValidationActive && isInvalid}
        isDisabled={isScreenFormLoading}
      />
    </Fragment>
  );
}

export function LoginCodeScreenContextForm({
  screenType,
  value,
  partiallyUpdate,
  error,
  isValidationActive,
  isScreenFormLoading,
}: ScreenContextFormProps) {
  const isInvalidBackground =
    hasErrorForKey(error, 'Image') ||
    (listRequiredScreenContexts(screenType).includes('backgroundImage') &&
      !value.backgroundImage);

  return (
    <Fragment>
      <Toggle
        label={screenContexts.qrCode}
        value={value.qrCode ?? undefined}
        defaultValue={false}
        isDisabled={isScreenFormLoading}
        setValue={(value) => partiallyUpdate({ qrCode: value })}
      />
      <NumberInput
        label={screenContexts.pollingInterval}
        value={value.pollingIntervalSeconds ?? undefined}
        units={screenContexts.seconds}
        isDisabled={isScreenFormLoading}
        minValue={1}
        defaultValue={7}
        onChange={(value) => partiallyUpdate({ pollingIntervalSeconds: value })}
      />
      <BackgroundImageInput
        value={value.backgroundImage}
        onChange={(imageKey) => partiallyUpdate({ backgroundImage: imageKey })}
        isInvalid={isValidationActive && isInvalidBackground}
        isDisabled={isScreenFormLoading}
      />
    </Fragment>
  );
}

export function SeriesOrMovieDetailScreenContextForm({
  screenType,
  value,
  partiallyUpdate,
  isScreenFormLoading,
  isValidationActive,
  error,
}: ScreenContextFormProps) {
  const isPrimaryBackgroundImageInvalid =
    hasErrorForKey(error, 'Image') ||
    (listRequiredScreenContexts(screenType).includes(
      'primaryBackgroundImage',
    ) &&
      !value.primaryBackgroundImage);
  const isSecondaryBackgroundImageInvalid =
    hasErrorForKey(error, 'Image') ||
    (listRequiredScreenContexts(screenType).includes(
      'secondaryBackgroundImage',
    ) &&
      !value.secondaryBackgroundImage);
  return (
    <Fragment>
      <RadioGroup
        label={screenContexts.backgroundImageLayout}
        value={value.backgroundImageLayout}
        setValue={(layout) =>
          partiallyUpdate({ backgroundImageLayout: layout as ImageLayout })
        }
        isDisabled={isScreenFormLoading}
      >
        <Radio value={ImageLayout.Landscape} label={strings.common.landscape} />
        <Radio value={ImageLayout.Square} label={strings.common.square} />
      </RadioGroup>
      <PrimaryBackgroundImageInput
        value={value.primaryBackgroundImage || ''}
        onChange={(imageKey) =>
          partiallyUpdate({ primaryBackgroundImage: imageKey })
        }
        isInvalid={isValidationActive && isPrimaryBackgroundImageInvalid}
        isDisabled={isScreenFormLoading}
      />
      <SecondaryBackgroundImageInput
        value={value.secondaryBackgroundImage || ''}
        onChange={(imageKey) =>
          partiallyUpdate({ secondaryBackgroundImage: imageKey })
        }
        isInvalid={isValidationActive && isSecondaryBackgroundImageInvalid}
        isDisabled={isScreenFormLoading}
      />
    </Fragment>
  );
}

// unique forms

export function RegisterScreenContextForm({
  screenType,
  value,
  partiallyUpdate,
  isValidationActive,
  error,
  isScreenFormLoading,
}: ScreenContextFormProps) {
  const isBackgroundImageInvalid =
    hasErrorForKey(error, 'Image') ||
    (listRequiredScreenContexts(screenType).includes('backgroundImage') &&
      !value.backgroundImage);
  return (
    <Fragment>
      <NumberInput
        tooltipText={screenContexts.passwordMinCharsTooltip}
        label={screenContexts.passwordMinChars}
        units={screenContexts.chars}
        value={value.passwordMinCharacters}
        onChange={(passwordMinCharacters) =>
          partiallyUpdate({ passwordMinCharacters })
        }
        isDisabled={isScreenFormLoading}
      />
      <Toggle
        label={screenContexts.passwordReqUpper}
        value={value.passwordUppercase}
        setValue={(passwordUppercase) => partiallyUpdate({ passwordUppercase })}
        isDisabled={isScreenFormLoading}
      />
      <Toggle
        label={screenContexts.passwordReqLower}
        value={value.passwordLowercase}
        setValue={(passwordLowercase) => partiallyUpdate({ passwordLowercase })}
        isDisabled={isScreenFormLoading}
      />
      <Toggle
        label={screenContexts.passwordReqNumber}
        value={value.passwordNumber}
        setValue={(passwordNumber) => partiallyUpdate({ passwordNumber })}
        isDisabled={isScreenFormLoading}
      />
      <Toggle
        label={screenContexts.passwordReqNonAlphanumeric}
        value={value.passwordNonAlphanumeric}
        setValue={(passwordNonAlphanumeric) =>
          partiallyUpdate({ passwordNonAlphanumeric })
        }
        isDisabled={isScreenFormLoading}
      />
      <BackgroundImageInput
        value={value.backgroundImage}
        onChange={(imageKey) => partiallyUpdate({ backgroundImage: imageKey })}
        isInvalid={isValidationActive && isBackgroundImageInvalid}
        isDisabled={isScreenFormLoading}
      />
    </Fragment>
  );
}

export function HomeScreenContextForm({
  value,
  partiallyUpdate,
  isScreenFormLoading,
  screenType,
  error,
  isValidationActive,
}: ScreenContextFormProps) {
  const isInvalid =
    hasErrorForKey(error, 'Image') ||
    (listRequiredScreenContexts(screenType).includes('backgroundImage') &&
      !value.backgroundImage);
  return (
    <Fragment>
      {/* <Toggle
        label={screenContexts.featuredShowcaseLabel}
        description={screenContexts.featuredShowcaseDescription}
        value={value.featuredShowcase}
        setValue={(featuredShowcase) => partiallyUpdate({ featuredShowcase })}
        isDisabled={isScreenFormLoading}
      /> */}
      <BackgroundImageInput
        value={value.backgroundImage}
        onChange={(imageKey) => partiallyUpdate({ backgroundImage: imageKey })}
        isInvalid={isValidationActive && isInvalid}
        isDisabled={isScreenFormLoading}
      />

      <RadioGroup
        label={screenContexts.backgroundImageLayout}
        value={value.backgroundImageLayout}
        setValue={(layout) =>
          partiallyUpdate({ backgroundImageLayout: layout as ImageLayout })
        }
        isDisabled={isScreenFormLoading}
      >
        <Radio value={ImageLayout.Landscape} label={strings.common.landscape} />
        <Radio value={ImageLayout.Square} label={strings.common.square} />
      </RadioGroup>
    </Fragment>
  );
}

export function AudioPlaybackScreenContextForm(props: ScreenContextFormProps) {
  const {
    screenType,
    value,
    partiallyUpdate,
    isValidationActive,
    error,
    isScreenFormLoading,
  } = props;
  const isBackgroundImageInvalid =
    hasErrorForKey(error, 'Image') ||
    (listRequiredScreenContexts(screenType).includes('backgroundImage') &&
      !value.backgroundImage);
  return (
    <Fragment>
      <VideoPlaybackScreenContextForm
        {...props}
        displayPrerollAd={false}
        isVideo={false}
      />
      {/* TODO v1.1: reintroduce background color */}
      {/* <ColorInput
        label={'End Card Background'}
        value={value.upNextBackgroundColor}
        setSanitizedValue={(upNextBackgroundColor) =>
          partiallyUpdate({ upNextBackgroundColor })
        }
        isDisabled={isScreenFormLoading}
      /> */}
      <BackgroundImageInput
        value={value.backgroundImage}
        onChange={(imageKey) => partiallyUpdate({ backgroundImage: imageKey })}
        isInvalid={isValidationActive && isBackgroundImageInvalid}
        isDisabled={isScreenFormLoading}
      />
    </Fragment>
  );
}

export function VideoPlaybackScreenContextForm({
  value,
  partiallyUpdate,
  isScreenFormLoading,
  displayPrerollAd = true,
  isVideo = true,
}: ScreenContextFormProps & { displayPrerollAd?: boolean; isVideo?: boolean }) {
  return (
    <Fragment>
      <NumberInput
        label={screenContexts.progressHeartbeat}
        units={screenContexts.seconds}
        value={value.progressHeartbeat}
        minValue={1}
        onChange={(progressHeartbeat) => partiallyUpdate({ progressHeartbeat })}
        isDisabled={isScreenFormLoading}
      />
      <NumberInput
        label={screenContexts.upNextCountdown}
        units={screenContexts.seconds}
        value={value.upNextCountdown}
        minValue={1}
        onChange={(upNextCountdown) => partiallyUpdate({ upNextCountdown })}
        isDisabled={isScreenFormLoading}
      />
      <NumberInput
        label={screenContexts.upNextCue}
        units={screenContexts.seconds}
        value={value.upNextCue}
        minValue={1}
        onChange={(upNextCue) => partiallyUpdate({ upNextCue })}
        isDisabled={isScreenFormLoading}
      />
      <RadioGroup
        label={
          isVideo
            ? screenContexts.imageLayout
            : screenContexts.backgroundImageLayout
        }
        value={value.imageLayout ?? ImageLayout.Landscape}
        setValue={(layout) =>
          partiallyUpdate({ imageLayout: layout as ImageLayout })
        }
        isDisabled={isScreenFormLoading}
      >
        <Radio value={ImageLayout.Landscape} label={strings.common.landscape} />
        <Radio value={ImageLayout.Square} label={strings.common.square} />
      </RadioGroup>
      {displayPrerollAd && (
        <NumberInput
          label={screenContexts.prerollAdFrequency}
          value={value.prerollAdFrequency}
          minValue={1}
          onChange={(prerollAdFrequency) =>
            partiallyUpdate({ prerollAdFrequency })
          }
          isDisabled={isScreenFormLoading}
        />
      )}
    </Fragment>
  );
}

export function RecommendationsChannelScreenContextForm({
  screenType,
  value,
  partiallyUpdate,
  isValidationActive,
  error,
  isScreenFormLoading,
}: ScreenContextFormProps) {
  const isInvalid =
    hasErrorForKey(error, 'Image') ||
    (listRequiredScreenContexts(screenType).includes('icon') && !value.icon);
  return (
    <IconInput
      value={value.icon}
      onChange={(iconKey) => partiallyUpdate({ icon: iconKey })}
      isInvalid={isValidationActive && isInvalid}
      isDisabled={isScreenFormLoading}
    />
  );
}

export function AreYouStillWatchingScreenContextForm({
  value,
  partiallyUpdate,
  isScreenFormLoading,
}: ScreenContextFormProps) {
  return (
    <Fragment>
      <NumberInput
        label={screenContexts.countdown}
        units={screenContexts.seconds}
        value={value.countdown}
        minValue={1}
        onChange={(countdown) => partiallyUpdate({ countdown })}
        isDisabled={isScreenFormLoading}
      />
      <NumberInput
        label={screenContexts.inactivityInterval}
        units={screenContexts.seconds}
        value={value.inactivityInterval}
        minValue={1}
        onChange={(inactivityInterval) =>
          partiallyUpdate({ inactivityInterval })
        }
        isDisabled={isScreenFormLoading}
      />
    </Fragment>
  );
}

export function HomeMiniPlayerScreenContextForm({
  value,
  partiallyUpdate,
  error,
  isScreenFormLoading,
  isValidationActive,
  screenType,
}: ScreenContextFormProps) {
  return (
    <Fragment>
      <SharedBackgroundImageScreenContextForm
        error={error}
        isScreenFormLoading={isScreenFormLoading}
        isValidationActive={isValidationActive}
        partiallyUpdate={partiallyUpdate}
        screenType={screenType}
        value={value}
      />
      <NumberInput
        label={screenContexts.autoFullScreenTransition}
        value={value.autoFullScreenTransition}
        units={screenContexts.seconds}
        isDisabled={isScreenFormLoading}
        onChange={(autoFullScreenTransition) =>
          partiallyUpdate({ autoFullScreenTransition })
        }
      />
    </Fragment>
  );
}
