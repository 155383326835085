import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '@fontsource/open-sans';
import './index.css';

import router from './router';
import extendedChakraTheme from './themes/extendedChakraTheme';

import useTokens from './hooks/useTokens';
import { TokenContext } from './contexts';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      // prevents any request if there is no connection,
      // when the connection is estrablished the queries will resume
      enabled: window.navigator.onLine,
    },
  },
});

export const App = () => {
  const tokenValues = useTokens();

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={extendedChakraTheme}>
        <DndProvider backend={HTML5Backend}>
          <TokenContext.Provider value={tokenValues}>
            <RouterProvider router={router} />
          </TokenContext.Provider>
        </DndProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
};
