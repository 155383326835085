import React, { Fragment } from 'react';
import {
  Center,
  HStack,
  Image as ChakraImage,
  Spacer,
  Text,
  Box,
} from '@chakra-ui/react';
import Button from '../buttons/Button';
import deleteIcon from '../../../assets/delete.svg';
import { Image, ImageType } from '../../../gql/gqlRequests';
import { getFileSizeText } from '../../../utils/file';
import {
  backgroundImageInputWidth,
  standardImageInputWidth,
  backgroundImageInputHeight,
  standardImageInputHeight,
} from './ImageInput';
import { strings } from '../../../utils/strings';
import ImageNotFound from '../ImageNotFound';

type ImageInputPreviewProps = {
  image: Image;
  imageType: ImageType;
  clearImage: () => void;
  isDisabled?: boolean;
  isInvalid?: boolean;
  disableGreyBg?: boolean;
};

export default function ImageInputPreview({
  image: image,
  imageType,
  clearImage,
  isDisabled = false,
  isInvalid = false,
  disableGreyBg = false,
}: ImageInputPreviewProps) {
  const imageWidth =
    imageType === ImageType.Standard
      ? standardImageInputWidth
      : backgroundImageInputWidth;
  const imageHeight =
    imageType === ImageType.Standard
      ? standardImageInputHeight
      : backgroundImageInputHeight;

  let fileName = image.filename;
  let fileSize = `(${getFileSizeText(image)})`;

  if (image.filename === 'unknown' && image.size > 0) {
    fileName = '';
  } else if (image.filename !== 'unknown' && image.size === 0) {
    fileSize = '';
  } else if (image.filename === 'unknown' && image.size === 0) {
    fileName = strings.files.unknown;
    fileSize = '';
  }

  if (imageType === ImageType.Standard) {
    return (
      <Fragment>
        <Box
          width={imageWidth}
          height={imageHeight}
          backgroundColor={disableGreyBg ? undefined : 'neutrals.brandGrey.400'}
          border="2px solid"
          borderRadius="10px"
          borderColor={
            isInvalid ? 'system.error.700' : 'neutrals.brandGrey.300'
          }
          marginBottom="5px"
        >
          <Center
            height="100%"
            overflow="hidden"
            padding="2px 0"
            margin="0 2px"
          >
            <ChakraImage
              src={image.url}
              alt={image.filename}
              objectFit="contain"
              fallback={<ImageNotFound />}
            />
          </Center>
        </Box>
        <RemoveImageButton
          clearImage={clearImage}
          imageWidth={imageWidth}
          isDisabled={isDisabled}
          fileName={fileName}
          fileSize={fileSize}
        />
      </Fragment>
    );
  }
  if (imageType === ImageType.Background) {
    return (
      <Fragment>
        <ChakraImage
          src={image.url}
          alt={image.filename}
          width={imageWidth}
          height={imageHeight}
          fallback={<ImageNotFound />}
          objectFit="contain"
          backgroundColor={disableGreyBg ? undefined : 'neutrals.brandGrey.400'}
          border="2px solid"
          borderRadius="10px"
          borderColor={
            isInvalid ? 'system.error.700' : 'neutrals.brandGrey.300'
          }
          marginBottom="5px"
        />
        <RemoveImageButton
          clearImage={clearImage}
          imageWidth={imageWidth}
          isDisabled={isDisabled}
          fileName={fileName}
          fileSize={fileSize}
        />
      </Fragment>
    );
  }

  // won't ever happen because we have both image types handled before
  return null;
}

type RemoveImageButtonProps = {
  clearImage: any;
  imageWidth: string;
  isDisabled: boolean;
  fileName: string;
  fileSize: string;
};

function RemoveImageButton({
  clearImage,
  imageWidth,
  isDisabled,
  fileName,
  fileSize,
}: RemoveImageButtonProps) {
  return (
    <Button
      // weird one-of-a-kind button
      variant="ghost"
      onClick={clearImage}
      paddingX="20px"
      minH="50px"
      height={'max-content'}
      width={imageWidth}
      backgroundColor="neutrals.staticWhite"
      isDisabled={isDisabled}
    >
      <HStack
        width="100%"
        spacing="3px"
        textStyle="bodyCopySmall"
        textColor="neutrals.brandGrey.500"
      >
        <Text
          maxW="350px"
          whiteSpace="initial"
          noOfLines={2}
          textAlign={'start'}
          lineHeight={5}
        >
          {fileName}
        </Text>
        <Text>{fileSize}</Text>

        <Spacer />
        <Center>
          <ChakraImage src={deleteIcon} alt={strings.common.delete} />
        </Center>
      </HStack>
    </Button>
  );
}
