import React from 'react';
import { UserContext } from '../../contexts';
import ErrorPage from './ErrorPage';
import { Role } from '../../gql/gqlRequests';

export const CheckExistingApp = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { loggedInUser } = React.useContext(UserContext);

  if (
    loggedInUser.appId.length === 0 &&
    loggedInUser.role !== Role.SystemAdmin
  ) {
    return <ErrorPage code={403} />;
  }

  return <>{children}</>;
};
