import { camelCaseEnum } from '.';
import {
  Screen,
  ScreenQuery,
  ScreenType,
  SubscriptionProduct,
} from '../gql/gqlRequests';
import {
  ScreenContexts,
  ScreenContextsInput,
  ScreenLocalizationInputData,
  ScreenTypeAndContext,
  Screen_ScreenFeedInputWithId,
} from '../types/screens';
import { strings } from './strings';

const {
  screenLocalizationDefaults,
  screenLocalizationLabels,
  screenLocalizationTooltips,
} = strings;

export function doesScreenTypeExistOnPlatforms(
  // if the defaults are needed then the data hasn't loaded yet so it doesn't matter what the answer is
  existingScreens: Pick<Screen, 'id' | 'type' | 'platforms'>[] = [],
  selectedScreenType: ScreenType | null,
  selectedPlatforms: string[] = [],
  // only applicable for editing
  currentId?: string,
) {
  return existingScreens.some(
    ({ id, type, platforms }) =>
      // ignore current screen being edited
      id !== currentId &&
      type === selectedScreenType &&
      platforms.some(({ id }) => selectedPlatforms.includes(id)),
  );
}

// screen contexts

export function hasScreenFeeds(screenType: ScreenType) {
  switch (screenType) {
    case ScreenType.Home:
    case ScreenType.Browse:
    case ScreenType.BrowseB:
    case ScreenType.BrowseC:
    case ScreenType.BrowseD:
    case ScreenType.Favorites:
    case ScreenType.ContinueWatching:
    case ScreenType.MovieDetail:
    case ScreenType.Search:
    case ScreenType.RecommendationsChannel:
    case ScreenType.HomeMiniPlayer:
      return true;

    default:
      return false;
  }
}

export function extractImageKeys(
  screen: ScreenQuery['screen'],
): Partial<ScreenContextsInput> {
  const { screenType, screenContext } = getScreenTypeAndContext(screen);

  switch (screenType) {
    case ScreenType.Splash:
    case ScreenType.Welcome:
    case ScreenType.Login:
    case ScreenType.Register:
    case ScreenType.ForgotPassword:
    case ScreenType.ForgotPasswordConfirmation:
    case ScreenType.ForgotPasswordExternal:
    case ScreenType.Browse:
    case ScreenType.BrowseB:
    case ScreenType.BrowseC:
    case ScreenType.BrowseD:
    case ScreenType.Favorites:
    case ScreenType.ContinueWatching:
    case ScreenType.Search:
    case ScreenType.Upsell:
    case ScreenType.Paywall:
    case ScreenType.ManageSubscription:
    case ScreenType.Account:
    case ScreenType.TermsService:
    case ScreenType.TermsPurchase:
    case ScreenType.Faq:
    case ScreenType.PrivacyPolicy:
    case ScreenType.CcpaPrivacy:
    case ScreenType.ThirdPartySoftware:
    case ScreenType.Support:
    case ScreenType.ForceUpdate:
    case ScreenType.AppExit:
    case ScreenType.LogoutConfirmation:
    case ScreenType.DeleteAccount:
    case ScreenType.DeleteAccountConfirmation:
    case ScreenType.PrivacyConsent:
    case ScreenType.AppSettings:
    case ScreenType.ParentalPin:
    case ScreenType.ParentalPinSet:
    case ScreenType.ParentalPinForgot:
    case ScreenType.HomeMiniPlayer:
    case ScreenType.ParentalPinForgotSet:
    case ScreenType.WelcomePrivacyConsent:
    case ScreenType.Error: {
      const { backgroundImage, ...rest } = screenContext;
      return {
        ...rest,
        backgroundImage: backgroundImage?.key,
      };
    }

    case ScreenType.LoginCode: {
      const { backgroundImage, qrCode, pollingIntervalSeconds } = screenContext;
      return {
        backgroundImage: backgroundImage?.key,
        qrCode: qrCode ?? undefined,
        pollingIntervalSeconds: pollingIntervalSeconds ?? undefined,
      };
    }
    case ScreenType.SeriesDetail:
    case ScreenType.MovieDetail: {
      const { primaryBackgroundImage, secondaryBackgroundImage, ...rest } =
        screenContext;
      return {
        ...rest,
        primaryBackgroundImage: primaryBackgroundImage?.key,
        secondaryBackgroundImage: secondaryBackgroundImage?.key,
      };
    }

    case ScreenType.RecommendationsChannel: {
      const { icon, ...rest } = screenContext;
      return {
        ...rest,
        icon: icon.key,
      };
    }

    case ScreenType.Home:
      const { backgroundImage, ...rest } = screenContext;
      return {
        backgroundImage: backgroundImage?.key,
        // featuredShowcase: featuredShowcase ?? true,
        ...rest,
      };

    case ScreenType.VideoPlayback:
      const { upNextCue, prerollAdFrequency, imageLayout, ...restV } =
        screenContext;

      return {
        ...restV,
        upNextCue: upNextCue ?? 0,
        prerollAdFrequency: prerollAdFrequency ?? 0,
        imageLayout: imageLayout ?? undefined,
      };
    case ScreenType.AudioPlayback:
      return {
        ...screenContext,
        upNextCue: screenContext.upNextCue ?? 0,
        backgroundImage: screenContext.backgroundImage?.key,
      };
    case ScreenType.AreYouStillWatching:
      return screenContext;

    default:
      throw Error('Unhandled screen type ' + screenType);
  }
}

export function keepOnlyApplicableProperties(
  screenType: ScreenType,
  screenContext: ScreenContextsInput,
): Partial<ScreenContextsInput> {
  const {
    backgroundImage,
    primaryBackgroundImage,
    secondaryBackgroundImage,
    icon,
    imageLayout,
    backgroundImageLayout,
    countdown,
    inactivityInterval,
    progressHeartbeat,
    upNextCountdown,
    passwordMinCharacters,
    passwordUppercase,
    passwordLowercase,
    passwordNumber,
    passwordNonAlphanumeric,
    qrCode,
    pollingIntervalSeconds,
    alwaysShowOnLaunch,
    upNextCue,
    prerollAdFrequency,
    autoFullScreenTransition,
    // featuredShowcase,
  } = screenContext;

  switch (screenType) {
    case ScreenType.Register:
      return {
        backgroundImage,
        passwordMinCharacters,
        passwordUppercase,
        passwordLowercase,
        passwordNumber,
        passwordNonAlphanumeric,
      };

    case ScreenType.Home:
      return { backgroundImageLayout, backgroundImage };

    case ScreenType.HomeMiniPlayer:
      return { autoFullScreenTransition, backgroundImage };

    case ScreenType.HomeMiniPlayer:
      return { autoFullScreenTransition, backgroundImage };

    case ScreenType.SeriesDetail:
    case ScreenType.MovieDetail:
      return {
        backgroundImageLayout,
        primaryBackgroundImage,
        secondaryBackgroundImage,
      };

    case ScreenType.AudioPlayback:
      return {
        backgroundImage,
        progressHeartbeat,
        upNextCountdown,
        imageLayout,
        upNextCue,
      };

    case ScreenType.VideoPlayback:
      return {
        progressHeartbeat,
        upNextCountdown,
        upNextCue,
        prerollAdFrequency,
        imageLayout,
      };

    case ScreenType.RecommendationsChannel:
      return { icon };

    case ScreenType.AreYouStillWatching:
      return { countdown, inactivityInterval };

    case ScreenType.LoginCode:
      return { backgroundImage, qrCode, pollingIntervalSeconds };

    case ScreenType.Welcome:
      return {
        backgroundImage,
        alwaysShowOnLaunch: alwaysShowOnLaunch ?? false,
      };

    case ScreenType.Splash:
    case ScreenType.Login:
    case ScreenType.Register:
    case ScreenType.ForgotPassword:
    case ScreenType.ForgotPasswordConfirmation:
    case ScreenType.ForgotPasswordExternal:
    case ScreenType.Browse:
    case ScreenType.BrowseB:
    case ScreenType.BrowseC:
    case ScreenType.BrowseD:
    case ScreenType.Favorites:
    case ScreenType.ContinueWatching:
    case ScreenType.Search:
    case ScreenType.AudioPlayback:
    case ScreenType.Upsell:
    case ScreenType.Paywall:
    case ScreenType.ManageSubscription:
    case ScreenType.Account:
    case ScreenType.TermsService:
    case ScreenType.TermsPurchase:
    case ScreenType.Faq:
    case ScreenType.PrivacyPolicy:
    case ScreenType.CcpaPrivacy:
    case ScreenType.ThirdPartySoftware:
    case ScreenType.Support:
    case ScreenType.ForceUpdate:
    case ScreenType.AppExit:
    case ScreenType.LogoutConfirmation:
    case ScreenType.DeleteAccount:
    case ScreenType.DeleteAccountConfirmation:
    case ScreenType.PrivacyConsent:
    case ScreenType.AppSettings:
    case ScreenType.ParentalPin:
    case ScreenType.ParentalPinSet:
    case ScreenType.ParentalPinForgot:
    case ScreenType.HomeMiniPlayer:
    case ScreenType.ParentalPinForgotSet:
    case ScreenType.WelcomePrivacyConsent:
    case ScreenType.Error:
      return { backgroundImage };

    default:
      throw Error('Unhandled screen type ' + screenType);
  }
}

export function validateScreenFeeds(
  screenType: ScreenType,
  screenFeeds: Screen_ScreenFeedInputWithId[],
) {
  switch (screenType) {
    case ScreenType.Home:
    case ScreenType.Browse:
    case ScreenType.BrowseB:
    case ScreenType.BrowseC:
    case ScreenType.BrowseD:
    case ScreenType.Favorites:
    case ScreenType.ContinueWatching:
    case ScreenType.Search:
    case ScreenType.RecommendationsChannel:
    case ScreenType.HomeMiniPlayer:
      return screenFeeds.length > 0;
    default:
      return true;
  }
}

export function listRequiredScreenContexts(
  screenType: ScreenType,
): (keyof ScreenContextsInput)[] {
  switch (screenType) {
    case ScreenType.Splash:
      return ['backgroundImage'];
    case ScreenType.Home:
      return ['backgroundImageLayout'];
    case ScreenType.SeriesDetail:
    case ScreenType.MovieDetail:
      return ['backgroundImageLayout'];
    case ScreenType.AudioPlayback:
      return [
        'backgroundImage',
        'progressHeartbeat',
        'upNextCountdown',
        'imageLayout',
      ];
    case ScreenType.VideoPlayback:
      return ['progressHeartbeat', 'upNextCountdown'];
    case ScreenType.RecommendationsChannel:
      return ['icon'];
    case ScreenType.AreYouStillWatching:
      return ['countdown', 'inactivityInterval'];
    default:
      return [];
  }
}

// TODO: probably best to do an explicit switch over all cases, for better type-safety?

export function getScreenTypeAndContext(
  screenData: ScreenQuery['screen'],
): ScreenTypeAndContext {
  const screenType = screenData.type;
  const screenContextKey = getScreenContextKey(screenType);

  if (!screenContextKey || !screenData[screenContextKey]) {
    throw new Error(
      "Can't find screen's context for the screen type " + screenType,
    );
  }

  return {
    screenType: screenData.type,
    screenContext: screenData[screenContextKey],
  } as ScreenTypeAndContext;
}

export function getScreenContextKey(
  screenType: ScreenType,
): keyof ScreenContexts {
  return (camelCaseEnum(screenType) + 'Screen') as keyof ScreenContexts;
}

// screen localizations

export function hasLocalizations(screenType: ScreenType) {
  switch (screenType) {
    case ScreenType.Splash:
    case ScreenType.RecommendationsChannel:
      return false;

    default:
      return true;
  }
}

/**
 * For use when submitting to backend.
 *
 * Omits non-applicable keys and converts to an array.
 */
export function createTranslationsArray(
  screenType: ScreenType,
  enabledSubscriptions: Pick<SubscriptionProduct, 'id'>[] = [],
  keyValuePairs: Record<string, string>,
) {
  const keysAndDefaults = getScreenLocalizationKeysDefaultsAndOptionalities(
    screenType,
    enabledSubscriptions,
  );
  return keysAndDefaults.map(({ key }) => ({
    key,
    translation: keyValuePairs[key]?.trim() ?? '',
  }));
}

/**
 * For use when setting default localizations when selecting screen type.
 *
 * Gets all possible keys and gives them the default values.
 */
export function createDefaultKeyValuePairs(
  screenType: ScreenType,
  potentialEnabledSubscriptions: Pick<SubscriptionProduct, 'id'>[] = [],
) {
  const keysAndDefaults = getScreenLocalizationKeysDefaultsAndOptionalities(
    screenType,
    potentialEnabledSubscriptions,
  );
  const keyValuePairs: Record<string, string> = {};
  keysAndDefaults.forEach(
    ({ key, defaultValue }) => (keyValuePairs[key] = defaultValue),
  );
  return keyValuePairs;
}

/**
 * For use when populating screen localizations from existing data.
 *
 * Gets all possible keys and attaches the existing key if available,
 * otherwise provides a common default or the specific default.
 */
export function createKeyValuePairs(
  screenType: ScreenType,
  potentialEnabledSubscriptions: Pick<SubscriptionProduct, 'id'>[] = [],
  localizations: ScreenQuery['screen']['localization'],
  commonDefault?: string,
) {
  const keysAndDefaults = getScreenLocalizationKeysDefaultsAndOptionalities(
    screenType,
    potentialEnabledSubscriptions,
  );
  const pairs: Record<string, string> = {};
  keysAndDefaults.forEach(
    ({ key, defaultValue }) =>
      (pairs[key] =
        localizations.find((localization) => localization.key === key)?.value ??
        commonDefault ??
        defaultValue),
  );
  return pairs;
}

export function validateScreenLocalizations(
  screenType: ScreenType,
  enabledSubscriptions: Pick<SubscriptionProduct, 'id'>[],
  keyValuePairs: Record<string, string>,
) {
  const keysAndOptionalities =
    getScreenLocalizationKeysDefaultsAndOptionalities(
      screenType,
      enabledSubscriptions,
    );
  return keysAndOptionalities.every(
    ({ key, isOptional }) => isOptional || !!keyValuePairs[key],
  );
}

function getScreenLocalizationKeysDefaultsAndOptionalities(
  screenType: ScreenType,
  enabledSubscriptions: Pick<SubscriptionProduct, 'id'>[] = [],
) {
  const individualKeysAndDefaults = getScreenLocalizationIndividualInputsData(
    screenType,
  ).flatMap(extractKeysDefaultsAndOptionalities);
  const perSubscriptionKeysAndDefaults =
    getScreenLocalizationSubscriptionsInputsData(screenType).flatMap(
      extractKeysDefaultsAndOptionalities,
    );
  const subscriptionKeysAndDefaults = enabledSubscriptions.flatMap(({ id }) =>
    perSubscriptionKeysAndDefaults.map(({ key, defaultValue, isOptional }) => ({
      key: key.replace(new RegExp(`\\\${id}`, 'g'), id),
      defaultValue,
      isOptional,
    })),
  );

  return [...individualKeysAndDefaults, ...subscriptionKeysAndDefaults];
}

function extractKeysDefaultsAndOptionalities({
  keys,
  defaultValues = [],
  isOptional,
}: ScreenLocalizationInputData) {
  return keys.map((key, ind) => ({
    key,
    defaultValue: defaultValues[ind] ?? '',
    isOptional,
  }));
}

/**
 * Adds subscription id to keys, adds group heading to first input,
 * and extracts values from pairs.
 */
export function parseAndAddValuesToLocalizations(
  inputsData: ScreenLocalizationInputData[],
  keyValuePairs: Record<string, string>,
  subscriptionId = '',
  groupHeading?: string,
) {
  return inputsData.map(
    ({ label, keys, defaultValues, tooltip, isFile, isOptional }, index) => {
      // for per-subscription pairs, the subscription id must be subbed-in
      const keysWithSuffixes = keys.map((key) =>
        key.replace(new RegExp(`\\\${id}`, 'g'), subscriptionId),
      );
      const values = keysWithSuffixes.map((key) => keyValuePairs[key] ?? '');

      return {
        label,
        tooltip,
        keys: keysWithSuffixes,
        values,
        defaultValues,
        groupHeading: index === 0 ? groupHeading : undefined,
        isFile,
        isOptional,
      };
    },
  );
}

export function getScreenLocalizationIndividualInputsData(
  screenType: ScreenType,
): ScreenLocalizationInputData[] {
  switch (screenType) {
    case ScreenType.Splash:
      return [];

    case ScreenType.Welcome:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.moviesShowsMore],
        },
        {
          label: screenLocalizationLabels.messages,
          keys: ['messages1', 'messages2', 'messages3'],
          defaultValues: [
            screenLocalizationDefaults.streamContent1,
            screenLocalizationDefaults.streamContent2,
          ],
          isOptional: true,
        },
        {
          label: screenLocalizationLabels.loginButton,
          keys: ['loginButton'],
          defaultValues: [screenLocalizationDefaults.signIn],
        },
        {
          label: screenLocalizationLabels.registerButton,
          keys: ['registerButton'],
          defaultValues: [screenLocalizationDefaults.signUp],
        },
        {
          label: screenLocalizationLabels.skipButton,
          keys: ['skipButton'],
          defaultValues: [screenLocalizationDefaults.skip],
        },
      ];

    case ScreenType.Login:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.signIn],
        },
        {
          label: screenLocalizationLabels.hintEmail,
          keys: ['hintEmail'],
          defaultValues: [screenLocalizationDefaults.email],
        },
        {
          label: screenLocalizationLabels.hintPassword,
          keys: ['hintPassword'],
          defaultValues: [screenLocalizationDefaults.password],
        },
        {
          label: screenLocalizationLabels.errorMessage,
          keys: ['error'],
          defaultValues: [screenLocalizationDefaults.invalidCredentials],
        },
        {
          label: screenLocalizationLabels.loginButton,
          keys: ['loginButton'],
          defaultValues: [screenLocalizationDefaults.signIn],
        },
        {
          label: screenLocalizationLabels.forgotPasswordButton,
          keys: ['forgotPasswordButton'],
          defaultValues: [screenLocalizationDefaults.forgotPassword],
        },
        {
          label: screenLocalizationLabels.registerButton,
          keys: ['registerButton'],
          defaultValues: [screenLocalizationDefaults.register],
        },
      ];

    case ScreenType.LoginCode:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.signInOrRegister],
        },
        {
          label: screenLocalizationLabels.instructions,
          keys: ['instruction'],
          defaultValues: [screenLocalizationDefaults.toSignInOrCreate],
        },
        {
          label: screenLocalizationLabels.qrInstruction,
          keys: ['qrInstruction'],
          defaultValues: [screenLocalizationDefaults.scanQrCode],
        },
        {
          label: screenLocalizationLabels.qrInstructionAlt,
          keys: ['qrInstructionAlt'],
          defaultValues: [screenLocalizationDefaults.unableToScanQrCode],
        },
        {
          label: screenLocalizationLabels.code,
          keys: ['code'],
          defaultValues: [screenLocalizationDefaults.enterCode],
        },
        {
          label: screenLocalizationLabels.separator,
          keys: ['separator'],
          defaultValues: [screenLocalizationDefaults.or],
        },
        {
          label: screenLocalizationLabels.loginButton,
          keys: ['loginButton'],
          defaultValues: [screenLocalizationDefaults.signInWithRemote],
        },
        {
          label: screenLocalizationLabels.registerButton,
          keys: ['registerButton'],
          defaultValues: [screenLocalizationDefaults.register],
        },
      ];

    case ScreenType.Register:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.register],
        },
        {
          label: screenLocalizationLabels.hintName,
          keys: ['hintName'],
          defaultValues: [screenLocalizationDefaults.name],
        },
        {
          label: screenLocalizationLabels.hintEmail,
          keys: ['hintEmail'],
          defaultValues: [screenLocalizationDefaults.email],
        },
        {
          label: screenLocalizationLabels.hintPassword,
          keys: ['hintPassword'],
          defaultValues: [screenLocalizationDefaults.password],
        },
        {
          label: screenLocalizationLabels.passwordCriteria,
          keys: ['passwordCriteria'],
          defaultValues: [screenLocalizationDefaults.passwordCriteria],
        },
        {
          label: screenLocalizationLabels.errorMessage,
          keys: ['error'],
          defaultValues: [screenLocalizationDefaults.errorMessage],
        },
        {
          label: screenLocalizationLabels.accountExistsErrorMessage,
          keys: ['errorExists'],
          defaultValues: [screenLocalizationDefaults.accountExists],
        },
        {
          label: screenLocalizationLabels.registerButton,
          keys: ['registerButton'],
          defaultValues: [screenLocalizationDefaults.register],
        },
        {
          label: screenLocalizationLabels.termsServiceButton,
          keys: ['termsServiceButton'],
          defaultValues: [screenLocalizationDefaults.termsOfService],
        },
        {
          label: screenLocalizationLabels.privacyPolicyButton,
          keys: ['privacyPolicyButton'],
          defaultValues: [screenLocalizationDefaults.privacyPolicy],
        },
        {
          label: screenLocalizationLabels.legalMessage,
          keys: ['legalMessage'],
          defaultValues: [screenLocalizationDefaults.agree],
        },
      ];

    case ScreenType.ForgotPassword:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.forgotPassword],
        },
        {
          label: screenLocalizationLabels.message,
          keys: ['message'],
          defaultValues: [screenLocalizationDefaults.forgotPasswordMessage],
        },
        {
          label: screenLocalizationLabels.hintEmail,
          keys: ['hintEmail'],
          defaultValues: [screenLocalizationDefaults.email],
        },
        {
          label: screenLocalizationLabels.errorMessage,
          keys: ['error'],
          defaultValues: [screenLocalizationDefaults.errorMessage],
        },
        {
          label: screenLocalizationLabels.submitButton,
          keys: ['submitButton'],
          defaultValues: [screenLocalizationDefaults.send],
        },
      ];

    case ScreenType.ForgotPasswordConfirmation:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.emailSent],
        },
        {
          label: screenLocalizationLabels.message,
          keys: ['message'],
          tooltip: screenLocalizationTooltips.email,
          defaultValues: [screenLocalizationDefaults.emailSentDetails],
        },
        {
          label: screenLocalizationLabels.backButton,
          keys: ['backButton'],
          defaultValues: [screenLocalizationDefaults.returnToSignIn],
        },
      ];

    case ScreenType.ForgotPasswordExternal:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.forgotPassword],
        },
        {
          label: screenLocalizationLabels.message,
          keys: ['message'],
          defaultValues: [screenLocalizationDefaults.visitForgotPassword],
        },
        {
          label: screenLocalizationLabels.backButton,
          keys: ['backButton'],
          defaultValues: [screenLocalizationDefaults.returnToSignIn],
        },
      ];

    case ScreenType.Home:
      return [
        {
          label: screenLocalizationLabels.showcaseDetailButton,
          keys: ['detailButton'],
          defaultValues: [screenLocalizationDefaults.startWatching],
        },
        {
          label: screenLocalizationLabels.showcasePlayButton,
          keys: ['playButton'],
          defaultValues: [screenLocalizationDefaults.startWatching],
        },
        {
          label: screenLocalizationLabels.showcaseResumeButton,
          keys: ['resumeButton'],
          defaultValues: [screenLocalizationDefaults.continue],
        },
        {
          label: screenLocalizationLabels.showcaseRestartButton,
          keys: ['restartButton'],
          defaultValues: [screenLocalizationDefaults.restart],
        },
      ];

    case ScreenType.Browse:
    case ScreenType.BrowseB:
    case ScreenType.BrowseC:
    case ScreenType.BrowseD:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.allShows],
        },
        {
          label: screenLocalizationLabels.emptyMessage,
          keys: ['emptyMessage'],
          defaultValues: [screenLocalizationDefaults.emptyBrowseMessage],
        },
      ];

    case ScreenType.ContinueWatching:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.continue],
        },
        {
          label: screenLocalizationLabels.emptyTitle,
          keys: ['emptyTitle'],
          defaultValues: [screenLocalizationDefaults.emptyContinue],
        },
        {
          label: screenLocalizationLabels.emptyMessage,
          keys: ['emptyMessage'],
          defaultValues: [screenLocalizationDefaults.emptyContinueMessage],
        },
        {
          label: screenLocalizationLabels.emptyButton,
          keys: ['emptyButton'],
          defaultValues: [screenLocalizationDefaults.browseContent],
        },
      ];
    case ScreenType.Favorites:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.watchlist],
        },
        {
          label: screenLocalizationLabels.emptyTitle,
          keys: ['emptyTitle'],
          defaultValues: [screenLocalizationDefaults.emptyWatchlist],
        },
        {
          label: screenLocalizationLabels.emptyMessage,
          keys: ['emptyMessage'],
          defaultValues: [screenLocalizationDefaults.addTitleHere],
        },
        {
          label: screenLocalizationLabels.emptyButton,
          keys: ['emptyButton'],
          defaultValues: [screenLocalizationDefaults.browseContent],
        },
      ];

    case ScreenType.SeriesDetail:
      return [
        {
          label: screenLocalizationLabels.playButton,
          keys: ['playButton'],
          defaultValues: [screenLocalizationDefaults.play],
        },
        {
          label: screenLocalizationLabels.resumeButton,
          keys: ['resumeButton'],
          defaultValues: [screenLocalizationDefaults.continue],
        },
        {
          label: screenLocalizationLabels.restartButton,
          keys: ['restartButton'],
          defaultValues: [screenLocalizationDefaults.restart],
        },
        {
          label: screenLocalizationLabels.trackProgressButton,
          keys: ['signInButton'],
          defaultValues: [screenLocalizationDefaults.signInToTrackProgress],
        },
        {
          label: screenLocalizationLabels.playbackTimeRemaining,
          keys: ['timeRemaining'],
          tooltip: screenLocalizationTooltips.minutesRemaining,
          defaultValues: [screenLocalizationDefaults.timeRemaining],
        },
        {
          label: screenLocalizationLabels.episodesTab,
          keys: ['episodesTab'],
          defaultValues: [screenLocalizationDefaults.episodes],
        },
        {
          label: screenLocalizationLabels.extrasTab,
          keys: ['extrasTab'],
          defaultValues: [screenLocalizationDefaults.extras],
        },
        {
          label: screenLocalizationLabels.seasonTitle,
          keys: ['season'],
          tooltip: screenLocalizationTooltips.season,
          defaultValues: [screenLocalizationDefaults.seasonTitle],
        },
        {
          label: screenLocalizationLabels.episodeDetailOverlayTitle,
          keys: ['episodeDetail'],
          defaultValues: [screenLocalizationDefaults.episodeDescription],
        },
        {
          label: screenLocalizationLabels.seriesDetailOverlayTitle,
          keys: ['seriesDetail'],
          defaultValues: [screenLocalizationDefaults.details],
        },
        {
          label: screenLocalizationLabels.releaseDate,
          keys: ['releaseDate'],
          defaultValues: [screenLocalizationDefaults.releaseDate],
        },
        {
          label: screenLocalizationLabels.genre,
          keys: ['genre'],
          defaultValues: [screenLocalizationDefaults.genre],
        },
        {
          label: screenLocalizationLabels.creator,
          keys: ['creator'],
          defaultValues: [screenLocalizationDefaults.creator],
        },
        {
          label: screenLocalizationLabels.creators,
          keys: ['creators'],
          defaultValues: [screenLocalizationDefaults.creators],
        },
        {
          label: screenLocalizationLabels.rating,
          keys: ['rating'],
          defaultValues: [screenLocalizationDefaults.rating],
        },
        {
          label: screenLocalizationLabels.cast,
          keys: ['cast'],
          defaultValues: [screenLocalizationDefaults.starring],
        },
      ];

    case ScreenType.MovieDetail:
      return [
        {
          label: screenLocalizationLabels.playButton,
          keys: ['playButton'],
          defaultValues: [screenLocalizationDefaults.play],
        },
        {
          label: screenLocalizationLabels.resumeButton,
          keys: ['resumeButton'],
          defaultValues: [screenLocalizationDefaults.continue],
        },
        {
          label: screenLocalizationLabels.restartButton,
          keys: ['restartButton'],
          defaultValues: [screenLocalizationDefaults.restart],
        },
        {
          label: screenLocalizationLabels.trackProgressButton,
          keys: ['signInButton'],
          defaultValues: [screenLocalizationDefaults.signInToTrackProgress],
        },
        {
          label: screenLocalizationLabels.playbackTimeRemaining,
          keys: ['timeRemaining'],
          tooltip: screenLocalizationTooltips.minutesRemaining,
          defaultValues: [screenLocalizationDefaults.timeRemaining],
        },
        {
          label: screenLocalizationLabels.movieDetailOverlayTitle,
          keys: ['movieDetail'],
          defaultValues: [screenLocalizationDefaults.details],
        },
        {
          label: screenLocalizationLabels.releaseDate,
          keys: ['releaseDate'],
          defaultValues: [screenLocalizationDefaults.releaseDate],
        },
        {
          label: screenLocalizationLabels.genre,
          keys: ['genre'],
          defaultValues: [screenLocalizationDefaults.genre],
        },
        {
          label: screenLocalizationLabels.creator,
          keys: ['creator'],
          defaultValues: [screenLocalizationDefaults.creator],
        },
        {
          label: screenLocalizationLabels.creators,
          keys: ['creators'],
          defaultValues: [screenLocalizationDefaults.creators],
        },
        {
          label: screenLocalizationLabels.rating,
          keys: ['rating'],
          defaultValues: [screenLocalizationDefaults.rating],
        },
        {
          label: screenLocalizationLabels.cast,
          keys: ['cast'],
          defaultValues: [screenLocalizationDefaults.starring],
        },
      ];

    case ScreenType.Search:
      return [
        {
          label: screenLocalizationLabels.hintSearch,
          keys: ['hintSearch'],
          defaultValues: [screenLocalizationDefaults.search],
        },
        {
          label: screenLocalizationLabels.emptyTitle,
          keys: ['emptyTitle'],
          defaultValues: [screenLocalizationDefaults.searchBy],
        },
        {
          label: screenLocalizationLabels.noResultsTitle,
          keys: ['noResultsTitle'],
          defaultValues: [screenLocalizationDefaults.noResults],
        },
        {
          label: screenLocalizationLabels.noResultsMessage,
          keys: ['noResultsMessage'],
          defaultValues: [screenLocalizationDefaults.tryDifferentSearch],
        },
      ];

    case ScreenType.AudioPlayback:
      return [
        {
          label: screenLocalizationLabels.upNextMessage,
          keys: ['upNext'],
          tooltip: screenLocalizationTooltips.countdownSeconds,
          defaultValues: [screenLocalizationDefaults.upNext],
        },
        {
          label: screenLocalizationLabels.errorTitle,
          keys: ['errorTitle'],
          defaultValues: [screenLocalizationDefaults.problemOccurred],
        },
        {
          label: screenLocalizationLabels.errorMessage,
          keys: ['errorMessage'],
          defaultValues: [screenLocalizationDefaults.havingTrouble],
        },
        {
          label: screenLocalizationLabels.errorButton,
          keys: ['errorButton'],
          defaultValues: [screenLocalizationDefaults.ok],
        },
      ];
    case ScreenType.VideoPlayback:
      return [
        {
          label: screenLocalizationLabels.upNextMessage,
          keys: ['upNext'],
          tooltip: screenLocalizationTooltips.countdownSeconds,
          defaultValues: [screenLocalizationDefaults.upNext],
        },
        {
          label: screenLocalizationLabels.errorTitle,
          keys: ['errorTitle'],
          defaultValues: [screenLocalizationDefaults.problemOccurred],
        },
        {
          label: screenLocalizationLabels.errorMessage,
          keys: ['errorMessage'],
          defaultValues: [screenLocalizationDefaults.havingTrouble],
        },
        {
          label: screenLocalizationLabels.errorButton,
          keys: ['errorButton'],
          defaultValues: [screenLocalizationDefaults.ok],
        },
        {
          label: screenLocalizationLabels.unavailable,
          keys: ['unavailable'],
          defaultValues: [screenLocalizationDefaults.unavailable],
        },
      ];

    case ScreenType.Upsell:
      return [
        {
          label: screenLocalizationLabels.playbackTitle,
          keys: ['playback_title'],
          defaultValues: [screenLocalizationDefaults.subscribersOnly],
        },
        {
          label: screenLocalizationLabels.playbackMessage,
          keys: ['playback_message'],
          defaultValues: [screenLocalizationDefaults.purchaseSubscription],
        },
        {
          label: screenLocalizationLabels.playbackConfirmButton,
          keys: ['playback_confirmButton'],
          defaultValues: [screenLocalizationDefaults.subscribe],
        },
        {
          label: screenLocalizationLabels.playbackDismissButton,
          keys: ['playback_cancelButton'],
          defaultValues: [screenLocalizationDefaults.cancel],
        },
        {
          label: screenLocalizationLabels.accountTitle,
          keys: ['account_title'],
          defaultValues: [screenLocalizationDefaults.upgrade],
        },
        {
          label: screenLocalizationLabels.accountMessage,
          keys: ['account_message'],
          defaultValues: [screenLocalizationDefaults.selectSubscribe],
        },
        {
          label: screenLocalizationLabels.accountConfirmButton,
          keys: ['account_confirmButton'],
          defaultValues: [screenLocalizationDefaults.subscribe],
        },
        {
          label: screenLocalizationLabels.accountCancelButton,
          keys: ['account_cancelButton'],
          defaultValues: [screenLocalizationDefaults.cancel],
        },
      ];

    case ScreenType.Paywall:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.choosePlan],
        },
        {
          label: screenLocalizationLabels.message,
          keys: ['message'],
          defaultValues: [screenLocalizationDefaults.freeTrialIncluded],
        },
        {
          label: screenLocalizationLabels.notice,
          keys: ['notice'],
          defaultValues: [screenLocalizationDefaults.freeTrialNewCustomersOnly],
        },
      ];

    case ScreenType.ManageSubscription:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.manageSubscription],
        },
        {
          label: screenLocalizationLabels.message,
          keys: ['message'],
          defaultValues: [screenLocalizationDefaults.subscriptionChanges],
        },
        {
          label: screenLocalizationLabels.url,
          keys: ['url'],
          defaultValues: [screenLocalizationDefaults.subscriptionUrl],
        },
      ];

    case ScreenType.Account:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.account],
        },
        {
          label: screenLocalizationLabels.email,
          keys: ['email'],
          tooltip: screenLocalizationTooltips.userEmail,
          defaultValues: [screenLocalizationDefaults.userEmail],
        },
        {
          label: screenLocalizationLabels.subscriptionStatus,
          keys: ['subscription_message'],
          tooltip: screenLocalizationTooltips.subscription,
          defaultValues: [screenLocalizationDefaults.subscriptionName],
        },
        {
          label: screenLocalizationLabels.message,
          keys: ['message'],
          defaultValues: [screenLocalizationDefaults.accountChanges],
        },
        {
          label: screenLocalizationLabels.supportButton,
          keys: ['support'],
          defaultValues: [screenLocalizationDefaults.support],
        },
        {
          label: 'App Settings',
          keys: ['appSettings'],
          defaultValues: [screenLocalizationDefaults.appSettings],
        },
        {
          label: screenLocalizationLabels.termsServiceButton,
          keys: ['termsService'],
          defaultValues: [screenLocalizationDefaults.termsOfService],
        },
        {
          label: screenLocalizationLabels.termsPurchaseButton,
          keys: ['termsPurchase'],
          defaultValues: [screenLocalizationDefaults.termsOfPurchase],
        },
        {
          label: screenLocalizationLabels.privacyPolicyButton,
          keys: ['privacyPolicy'],
          defaultValues: [screenLocalizationDefaults.privacyPolicy],
        },
        {
          label: screenLocalizationLabels.ccpaButton,
          keys: ['ccpaPrivacy'],
          defaultValues: [screenLocalizationDefaults.ccpaPrivacy],
        },
        {
          label: screenLocalizationLabels.thirdPartySoftwareButton,
          keys: ['thirdPartySoftware'],
          defaultValues: [screenLocalizationDefaults.thirdPartSoftware],
        },
        {
          label: screenLocalizationLabels.privacyConsentButton,
          keys: ['privacyConsent'],
          defaultValues: [screenLocalizationDefaults.privacyConsent],
        },
        {
          label: screenLocalizationLabels.logoutButton,
          keys: ['logout'],
          defaultValues: [screenLocalizationDefaults.signOut],
        },
        {
          label: screenLocalizationLabels.loginButton,
          keys: ['login'],
          defaultValues: [screenLocalizationDefaults.signIn],
        },
        {
          label: screenLocalizationLabels.deleteAccountButton,
          keys: ['deleteAccount'],
          defaultValues: [screenLocalizationDefaults.deleteAccount],
        },
        {
          label: screenLocalizationLabels.subscribeButton,
          keys: ['subscription_button_none'],
          defaultValues: [screenLocalizationDefaults.upgrade],
        },
        {
          label: screenLocalizationLabels.noSubscriptionName,
          keys: ['subscription_products_none'],
          defaultValues: [screenLocalizationDefaults.streams],
        },
        {
          label: screenLocalizationLabels.version,
          keys: ['version'],
          tooltip: screenLocalizationTooltips.version,
          defaultValues: [screenLocalizationDefaults.version],
        },
      ];

    case ScreenType.TermsService:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.termsOfService],
        },
        {
          label: screenLocalizationLabels.content,
          keys: ['contentKey'],
          isFile: true,
        },
      ];

    case ScreenType.TermsPurchase:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.termsOfPurchase],
        },
        {
          label: screenLocalizationLabels.content,
          keys: ['contentKey'],
          isFile: true,
        },
      ];

    case ScreenType.Faq:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.faq],
        },
        {
          label: screenLocalizationLabels.content,
          keys: ['contentKey'],
          isFile: true,
        },
      ];

    case ScreenType.PrivacyPolicy:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.privacyPolicy],
        },
        {
          label: screenLocalizationLabels.content,
          keys: ['contentKey'],
          isFile: true,
        },
      ];

    case ScreenType.CcpaPrivacy:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.ccpaPrivacy],
        },
        {
          label: screenLocalizationLabels.content,
          keys: ['contentKey'],
          isFile: true,
        },
      ];

    case ScreenType.ThirdPartySoftware:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.thirdPartSoftware],
        },
        {
          label: screenLocalizationLabels.content,
          keys: ['contentKey'],
          isFile: true,
        },
      ];

    case ScreenType.Support:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.support],
        },
        {
          label: screenLocalizationLabels.message,
          keys: ['message'],
          defaultValues: [screenLocalizationDefaults.forHelp],
        },
        {
          label: screenLocalizationLabels.url,
          keys: ['url'],
          defaultValues: [screenLocalizationDefaults.supportUrl],
        },
      ];

    case ScreenType.RecommendationsChannel:
      return [];

    case ScreenType.ForceUpdate:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.newAppVersion],
        },
        {
          label: screenLocalizationLabels.message,
          keys: ['message'],
          defaultValues: [screenLocalizationDefaults.mustUpgrade],
        },
        {
          label: screenLocalizationLabels.confirmButton,
          keys: ['confirmButton'],
          defaultValues: [screenLocalizationDefaults.ok],
        },
      ];

    case ScreenType.AppExit:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.confirmExit],
        },
        {
          label: screenLocalizationLabels.confirmButton,
          keys: ['confirmButton'],
          defaultValues: [screenLocalizationDefaults.yes],
        },
        {
          label: screenLocalizationLabels.dismissButton,
          keys: ['cancelButton'],
          defaultValues: [screenLocalizationDefaults.no],
        },
      ];

    case ScreenType.LogoutConfirmation:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.confirmSignOut],
        },
        {
          label: screenLocalizationLabels.confirmButton,
          keys: ['confirmButton'],
          defaultValues: [screenLocalizationDefaults.signOut],
        },
        {
          label: screenLocalizationLabels.dismissButton,
          keys: ['cancelButton'],
          defaultValues: [screenLocalizationDefaults.cancel],
        },
      ];

    case ScreenType.DeleteAccount:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.deleteAccount],
        },
        {
          label: screenLocalizationLabels.message,
          keys: ['message'],
          defaultValues: [screenLocalizationDefaults.willPermanentlyRemoveData],
        },
        {
          label: screenLocalizationLabels.confirmButton,
          keys: ['confirmButton'],
          defaultValues: [screenLocalizationDefaults.deleteAccount],
        },
        {
          label: screenLocalizationLabels.dismissButton,
          keys: ['cancelButton'],
          defaultValues: [screenLocalizationDefaults.cancel],
        },
      ];

    case ScreenType.DeleteAccountConfirmation:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.areYouSure],
        },
        {
          label: screenLocalizationLabels.message,
          keys: ['message'],
          defaultValues: [screenLocalizationDefaults.willInitiateDeletion],
        },
        {
          label: screenLocalizationLabels.confirmButton,
          keys: ['confirmButton'],
          defaultValues: [screenLocalizationDefaults.deleteAccount],
        },
        {
          label: screenLocalizationLabels.dismissButton,
          keys: ['cancelButton'],
          defaultValues: [screenLocalizationDefaults.cancel],
        },
      ];

    case ScreenType.AreYouStillWatching:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          tooltip: screenLocalizationTooltips.title,
          defaultValues: [screenLocalizationDefaults.stillWatching],
        },
        {
          label: screenLocalizationLabels.confirmButton,
          keys: ['confirmButton'],
          defaultValues: [screenLocalizationDefaults.continue],
        },
        {
          label: screenLocalizationLabels.dismissButton,
          keys: ['cancelButton'],
          tooltip: screenLocalizationTooltips.countdownSeconds,
          defaultValues: [screenLocalizationDefaults.exitTime],
        },
      ];
    case ScreenType.AppSettings:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.appSettings],
        },
        {
          label: screenLocalizationLabels.parentalPinTitle,
          keys: ['parentalPINTitle'],
          defaultValues: [screenLocalizationDefaults.parentalPin],
        },
        {
          label: screenLocalizationLabels.settingsParentalPINMessage,
          keys: ['parentalPINMessage'],
          defaultValues: [screenLocalizationDefaults.appSettingsParentalPin],
        },
      ];
    case ScreenType.PrivacyConsent:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.title],
        },
        {
          label: screenLocalizationLabels.toggle,
          keys: ['toggle'],
          defaultValues: [screenLocalizationDefaults.toggle],
        },
        {
          label: screenLocalizationLabels.description,
          keys: ['description'],
          defaultValues: [screenLocalizationDefaults.description],
        },
      ];
    case ScreenType.ParentalPin:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.parentalPin],
        },
        {
          label: screenLocalizationLabels.message,
          keys: ['message'],
          defaultValues: [screenLocalizationDefaults.enterParentalPinToStart],
        },
        {
          label: screenLocalizationLabels.errorMessage,
          keys: ['errorMessage'],
          defaultValues: [screenLocalizationDefaults.invalidPIN],
        },
        {
          label: screenLocalizationLabels.submitButton,
          keys: ['submitButton'],
          defaultValues: [screenLocalizationDefaults.submitButtonContinue],
        },
        {
          label: screenLocalizationLabels.forgotButton,
          keys: ['forgotButton'],
          defaultValues: [screenLocalizationDefaults.forgotButton],
        },
        {
          label: screenLocalizationLabels.cancelButton,
          keys: ['cancelButton'],
          defaultValues: [screenLocalizationDefaults.cancel],
        },
      ];
    case ScreenType.ParentalPinSet:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.parentalPinSet],
        },
        {
          label: screenLocalizationLabels.message,
          keys: ['message'],
          defaultValues: [screenLocalizationDefaults.messageEnterNewCode],
        },
        {
          label: screenLocalizationLabels.errorMessage,
          keys: ['errorMessage'],
          defaultValues: [screenLocalizationDefaults.pinMust4Digit],
        },
        {
          label: screenLocalizationLabels.submitButton,
          keys: ['submitButton'],
          defaultValues: [screenLocalizationDefaults.submitButtonContinue],
        },
        {
          label: screenLocalizationLabels.cancelButton,
          keys: ['cancelButton'],
          defaultValues: [screenLocalizationDefaults.cancel],
        },
      ];
    case ScreenType.ParentalPinForgot:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.parentalPinForgot],
        },
        {
          label: screenLocalizationLabels.question,
          keys: ['question'],
          defaultValues: [screenLocalizationDefaults.question],
        },
        {
          label: screenLocalizationLabels.errorMessage,
          keys: ['errorMessage'],
          defaultValues: [screenLocalizationDefaults.incorrectAnswer],
        },
        {
          label: screenLocalizationLabels.submitButton,
          keys: ['submitButton'],
          defaultValues: [screenLocalizationDefaults.submitButtonContinue],
        },
        {
          label: screenLocalizationLabels.cancelButton,
          keys: ['cancelButton'],
          defaultValues: [screenLocalizationDefaults.cancel],
        },
      ];
    case ScreenType.ParentalPinForgotSet:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.parentalPinForgotSet],
        },
        {
          label: screenLocalizationLabels.errorMessage,
          keys: ['errorMessage'],
          defaultValues: [screenLocalizationDefaults.invalidPIN],
        },
        {
          label: screenLocalizationLabels.submitButton,
          keys: ['submitButton'],
          defaultValues: [screenLocalizationDefaults.submitButton],
        },
        {
          label: screenLocalizationLabels.cancelButton,
          keys: ['cancelButton'],
          defaultValues: [screenLocalizationDefaults.cancel],
        },
      ];
    case ScreenType.HomeMiniPlayer:
      return [
        {
          label: screenLocalizationLabels.showcaseFullscreenButton,
          keys: ['fullScreenButton'],
          defaultValues: [screenLocalizationDefaults.showcaseFullscreenDefault],
        },
        {
          label: screenLocalizationLabels.embeddedPlayerErrorTitle,
          keys: ['embeddedPlayerErrorTitle'],
          defaultValues: [
            screenLocalizationDefaults.embeddedPlayerErrorTitleDefault,
          ],
        },
        {
          label: screenLocalizationLabels.embeddedPlayerErrorMessage,
          keys: ['embeddedPlayerErrorMessage'],
          defaultValues: [
            screenLocalizationDefaults.embeddedPlayerErrorMessageDefault,
          ],
        },
      ];
    case ScreenType.WelcomePrivacyConsent:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['title'],
          defaultValues: [screenLocalizationDefaults.title],
        },
        {
          label: screenLocalizationLabels.description,
          keys: ['description'],
          defaultValues: [screenLocalizationDefaults.description],
        },
        {
          label: screenLocalizationLabels.agreeButton,
          keys: ['agreeButton'],
          defaultValues: [screenLocalizationDefaults.agreeButton],
        },
        {
          label: screenLocalizationLabels.disagreeButton,
          keys: ['disagreeButton'],
          defaultValues: [screenLocalizationDefaults.disagreeButton],
        },
      ];
    case ScreenType.Error:
      return [
        {
          label: screenLocalizationLabels.offlineTitle,
          keys: ['offlineTitle'],
          defaultValues: [screenLocalizationDefaults.noInternet],
        },
        {
          label: screenLocalizationLabels.offlineMessage,
          keys: ['offlineMessage'],
          defaultValues: [screenLocalizationDefaults.couldNotConnect],
        },
        {
          label: screenLocalizationLabels.retryTitle,
          keys: ['retryTitle'],
          defaultValues: [screenLocalizationDefaults.problemOccurred],
        },
        {
          label: screenLocalizationLabels.retryMessage,
          keys: ['retryMessage'],
          defaultValues: [screenLocalizationDefaults.unableToLoad],
        },
        {
          label: screenLocalizationLabels.retryButton,
          keys: ['retryButton'],
          defaultValues: [screenLocalizationDefaults.retry],
        },
        {
          label: screenLocalizationLabels.tryAgainTitle,
          keys: ['tryAgainTitle'],
          defaultValues: [screenLocalizationDefaults.problemOccurred],
        },
        {
          label: screenLocalizationLabels.tryAgainMessage,
          keys: ['tryAgainMessage'],
          defaultValues: [screenLocalizationDefaults.tryAgainLater],
        },
        {
          label: screenLocalizationLabels.tryAgainButton,
          keys: ['tryAgainButton'],
          defaultValues: [screenLocalizationDefaults.ok],
        },
      ];

    default:
      throw Error('No screen localization keys for screen type ' + screenType);
  }
}

export function getScreenLocalizationSubscriptionsInputsData(
  screenType: ScreenType,
): ScreenLocalizationInputData[] {
  switch (screenType) {
    case ScreenType.Paywall:
      return [
        {
          label: screenLocalizationLabels.title,
          keys: ['${id}_title'],
          defaultValues: [screenLocalizationDefaults.streamsPlusAnnual],
        },
        {
          label: screenLocalizationLabels.tag,
          keys: ['${id}_tag'],
          defaultValues: [screenLocalizationDefaults.mostPopular],
          isOptional: true,
        },
        {
          label: screenLocalizationLabels.benefits,
          keys: ['${id}_benefits1', '${id}_benefits2', '${id}_benefits3'],
          defaultValues: [
            screenLocalizationDefaults.twoWeekFreeTrial,
            screenLocalizationDefaults.saveFortyPercent,
            screenLocalizationDefaults.exclusiveWatching,
          ],
          isOptional: true,
        },
        {
          label: screenLocalizationLabels.price,
          keys: ['${id}_price'],
          defaultValues: [screenLocalizationDefaults.price],
        },
        {
          label: screenLocalizationLabels.detail,
          keys: ['${id}_detail'],
          defaultValues: [screenLocalizationDefaults.priceDetail],
        },
      ];

    case ScreenType.Account:
      return [
        {
          label: screenLocalizationLabels.subscriptionProductName,
          keys: ['subscription_products_${id}'],
          defaultValues: [screenLocalizationDefaults.streamsPlus],
        },
        {
          label: screenLocalizationLabels.subscriptionProductButton,
          keys: ['subscription_button_${id}'],
          defaultValues: [screenLocalizationDefaults.manageSubscription],
        },
      ];

    default:
      return [];
  }
}
