import { IntegrationType } from '../gql/gqlRequests';

export function integrationWithConfigurableValuesPopulate(
  settings: Record<string, any> = {},
) {
  return {
    ...Object.keys(settings).reduce((current, key) => {
      if (typeof settings[key] === 'object') {
        current[key] = JSON.stringify(settings[key]);
      } else {
        current[key] = settings[key];
      }
      return current;
    }, {} as Record<string, any>),
  };
}

export function integrationWithConfigurableValues(
  integrationKey: string,
  integrationType: IntegrationType | null,
) {
  const configurableValues = {
    configurableValuesLogs:
      integrationType === IntegrationType.Logs &&
      ['new_relic', 'crashlytics'].includes(integrationKey),
    configurableValuesImage: integrationType === IntegrationType.Image,
    configurableValuesAnalytics:
      integrationType === IntegrationType.Analytics &&
      ['mparticle', 'amplitude', 'adobe_analytics', 'conviva'].includes(
        integrationKey,
      ),
    configurableValuesAbobeAnalytics:
      integrationType === IntegrationType.Analytics &&
      integrationKey === 'adobe_analytics',
    configurableValuesNexstarPlayer:
      integrationType === IntegrationType.Playback &&
      integrationKey === 'nexstar',
  };

  return {
    showConfigurableValues: Object.values(configurableValues).some(
      (val) => val,
    ),
    ...configurableValues,
  };
}

export function integrationConfigurableValuesDefaults(
  integrationKey: string,
  integrationType: IntegrationType | null,
  settings: Record<string, any>,
) {
  switch (true) {
    case integrationKey === 'nexstar':
      switch (integrationType) {
        case IntegrationType.Playback:
          return {
            vodAdUrl: settings?.vodAdUrl ?? undefined,
            context: !!settings?.context
              ? JSON.parse(settings.context)
              : undefined,
          };
        default:
          return {};
      }
    case integrationType === IntegrationType.Image:
      return {
        baseURL: settings?.baseURL ?? undefined,
      };
    case integrationType === IntegrationType.Logs &&
      ['new_relic', 'crashlytics'].includes(integrationKey):
      return {
        enabled: settings?.enabled ?? true,
        level: settings?.level ?? undefined,
      };
    case integrationType === IntegrationType.Analytics &&
      ['mparticle', 'amplitude', 'adobe_analytics', 'conviva'].includes(
        integrationKey,
      ):
      return {
        ...integrationConfigurableValuesSpecifics(
          integrationKey,
          integrationType,
          settings,
        ),
        playbackHeartbeat:
          settings?.playbackHeartbeat === null ||
          settings?.playbackHeartbeat === undefined
            ? integrationKey === 'adobe_analytics'
              ? 1
              : 30
            : settings?.playbackHeartbeat,
      };
    default:
      return {};
  }
}

function integrationConfigurableValuesSpecifics(
  integrationKey: string,
  integrationType: IntegrationType | null,
  settings: Record<string, any>,
) {
  switch (true) {
    case integrationType === IntegrationType.Analytics &&
      integrationKey === 'adobe_analytics':
      return {
        context: !!settings?.context ? JSON.parse(settings.context) : undefined,
      };
  }
}
