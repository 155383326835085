import React, { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as ChakraTabs,
} from '@chakra-ui/react';

export type TabData = {
  path: string;
  label: string;
  content: ReactNode;
  onChange?: () => void;
};

type TabsInput = {
  data: TabData[];
  spacing?: string;
  onChange?: () => void;
};

/**
 * Provided `data` should include both `content` to display and
 * `path` to navigate to. The selected tab will render the content,
 * and clicking another tab will navigate to its `path`.
 *
 * This is a bit of a hack since we need to track the selected tab
 * as part of the route but Chakra's Tabs isn't designed for this.
 * To use `Tabs` "normally", some refactoring would be required.
 */
export default function Tabs({ data, spacing, onChange }: TabsInput) {
  const location = useLocation();
  const navigate = useNavigate();

  // default to first tab if none of the tab paths match the current url path
  const selectedIndex = Math.max(
    0,
    data.map(({ path }) => path).indexOf(location.pathname),
  );

  // note that onChange doesn't actually change the `index` prop of `ChakraTabs`
  // instead we navigate to a new page (which will have that tab open)
  function navigateToTab(newIndex: number) {
    navigate(data[newIndex].path);
    onChange?.();
  }

  return (
    <ChakraTabs
      // lazy render because there are queries triggered in some tabs
      isLazy
      index={selectedIndex}
      onChange={navigateToTab}
      paddingBottom="40px"
    >
      <TabList>
        {data.map(({ label, path }, ind) => (
          <Tab
            key={path}
            textStyle="subtitle2"
            color={
              ind === selectedIndex
                ? 'neutrals.navigationOutline'
                : 'neutrals.brandGrey.unselected'
            }
            marginRight={spacing}
          >
            {label}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {data.map(({ content, path }) => (
          <TabPanel paddingTop={0} key={path}>
            {content}
          </TabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  );
}
