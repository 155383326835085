import {
  Box,
  Center,
  HStack,
  IconButton,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AppContext, EnvironmentContext, UserContext } from '../../../contexts';
import { routes } from '../../../types/routes';
import { strings } from '../../../utils/strings';
import CreateButton from '../../common/buttons/CreateButton';
import RowInput from '../../common/inputs/RowInput';
import LocalesTabList from '../../common/LocalesTabList';
import Page from '../../common/Page';
import Tabs, { TabData } from '../../common/Tabs';
import trashIcon from '../../../assets/trash.svg';
import trashedIcon from '../../../assets/trashed.svg';
import bookmarkIcon from '../../../assets/bookmark.svg';
import {
  Environment,
  LocalizationNamespace,
  LocalizedTranslationsQuery,
  LocalizedTranslationsQueryVariables,
  Role,
  SetLocalizedTranslationsMutation,
  SetLocalizedTranslationsMutationVariables,
  UpdateLocalizedTranslationsMutation,
  UpdateLocalizedTranslationsMutationVariables,
} from '../../../gql/gqlRequests';
import SecondaryButton from '../../common/buttons/SecondaryButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import useAuthRequest from '../../../hooks/useAuthRequest';
import {
  localizedTranslationsRequest,
  setLocalizedTranslations,
  updateLocalizedTranslations,
} from '../../../support/localizedTranslations';
import { useMutation, useQuery } from '@tanstack/react-query';
import { RequestError } from '../../../types';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import Form, { FormError } from '../../common/Form';
import {
  buildDefaultAllLocalizedTranslationInputs,
  getCurrentLocalizationNamespace,
  sanitizeExtendedUpdateLocalizedTranslationInputs,
} from '../../../utils/tags';
import { AllLocalizedTranslationInputs } from '../../../types/tags';
import { ALPHANUMERIC } from '../../../utils/regexp';
import { parsedRequestError } from '../../../utils/errors';
import StandardDeletionDialog from '../../common/dialogs/StandardDeletionDialog';
import { scrollToTop } from '../../../utils';
import FormPrompt from '../../common/FormPrompt';
import fastDeepEqual from 'fast-deep-equal';

export default function Tags() {
  const pageRef = useRef<HTMLDivElement>(null);
  const { appId, defaultLocale, locales } = useContext(AppContext);
  const { loggedInUser } = useContext(UserContext);
  const { environment } = useContext(EnvironmentContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [isTouched, setIsTouched] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState(defaultLocale);
  const [newLocalizedTranslationKey, setNewLocalizedTranslationKey] =
    useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [addTranslationKeyError, setAddTranslationKeyError] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState('');

  const [
    allLocalizedTranslationsOriginal,
    setAllLocalizedTranslationsOriginal,
  ] = useState<AllLocalizedTranslationInputs>(() =>
    buildDefaultAllLocalizedTranslationInputs(locales),
  );
  const [allLocalizedTranslations, setAllLocalizedTranslations] =
    useState<AllLocalizedTranslationInputs>(() =>
      buildDefaultAllLocalizedTranslationInputs(locales),
    );

  const currLocalizationNamespace = getCurrentLocalizationNamespace(
    location.pathname,
  );
  const isSysAdmin = loggedInUser.role === Role.SystemAdmin;
  const isProdEnv = environment === Environment.Production;

  const localizedTranslationsQueryFn = useAuthRequest<
    LocalizedTranslationsQueryVariables,
    LocalizedTranslationsQuery
  >(localizedTranslationsRequest);

  const localizedTranslationsQuery = useQuery<
    LocalizedTranslationsQuery,
    RequestError
  >({
    queryKey: [
      'localizedTranslations',
      appId,
      environment,
      currLocalizationNamespace,
      selectedLocale.code,
    ],
    queryFn: () =>
      localizedTranslationsQueryFn({
        appId,
        environment,
        namespace: currLocalizationNamespace,
        localeCode: selectedLocale.code,
      }),
    onSuccess: initializeInputs,
  });
  const refetchLocalizedTranslations = localizedTranslationsQuery.refetch;

  const setLocalizedTranslationsMutationFn = useAuthRequest<
    SetLocalizedTranslationsMutationVariables,
    SetLocalizedTranslationsMutation
  >(setLocalizedTranslations);

  const setLocalizedTranslationsMutation = useMutation<
    SetLocalizedTranslationsMutation,
    RequestError,
    SetLocalizedTranslationsMutationVariables
  >({
    mutationFn: setLocalizedTranslationsMutationFn,
    onSuccess: onSaveSuccess,
    onError: () => setErrorMessage(strings.errors.failedToSave),
  });
  const mutateSetLocalizedTranslations =
    setLocalizedTranslationsMutation.mutate;

  const updateLocalizedTranslationsMutationFn = useAuthRequest<
    UpdateLocalizedTranslationsMutationVariables,
    UpdateLocalizedTranslationsMutation
  >(updateLocalizedTranslations);

  const updateLocalizedTranslationsMutation = useMutation<
    UpdateLocalizedTranslationsMutation,
    RequestError,
    UpdateLocalizedTranslationsMutationVariables
  >({
    mutationFn: updateLocalizedTranslationsMutationFn,
    onSuccess: onSaveSuccess,
    onError: () => setErrorMessage(strings.errors.failedToSave),
  });
  const mutateUpdateLocalizedTranslations =
    updateLocalizedTranslationsMutation.mutate;

  useEffect(() => {
    refetchLocalizedTranslations();
  }, [currLocalizationNamespace, refetchLocalizedTranslations, selectedLocale]);

  function clearErrors() {
    setErrorMessage('');
    setAddTranslationKeyError(false);
  }

  function onSaveSuccess(
    data:
      | SetLocalizedTranslationsMutation
      | UpdateLocalizedTranslationsMutation,
  ) {
    const isSetSuccessfully =
      'setLocalizedTranslations' in data && data.setLocalizedTranslations;
    const isUpdatedSuccessfully =
      'updateLocalizedTranslations' in data && data.updateLocalizedTranslations;

    if (isSetSuccessfully || isUpdatedSuccessfully) {
      const allLocalizedTranslationsClone = _.cloneDeep(
        allLocalizedTranslations,
      );
      Object.values(LocalizationNamespace).forEach((namespace) => {
        locales.forEach(({ code }) => {
          allLocalizedTranslationsClone[namespace][code] =
            sanitizeExtendedUpdateLocalizedTranslationInputs(
              allLocalizedTranslationsClone[namespace][code],
            );
        });
      });
      setAllLocalizedTranslations(allLocalizedTranslationsClone);
      setAllLocalizedTranslationsOriginal(allLocalizedTranslationsClone);
      setIsTouched(false);
      clearErrors();
    } else {
      setErrorMessage(strings.errors.failedToSave);
    }
  }

  const handleOnSave = React.useCallback(() => {
    const { errors } = verifyLocaleTranslationInputs();
    if (errors) {
      setErrorMessage(errors.message);
      scrollToTop(pageRef);
      // Navigate to first tab with errors
      switch (errors.namespace) {
        case LocalizationNamespace.Tag:
          return navigate(routes.appTags({ appId }));
        case LocalizationNamespace.Badge:
          return navigate(routes.appTagsBadges({ appId }));
        case LocalizationNamespace.TuneIn:
          return navigate(routes.appTagsTuneIn({ appId }));
        default:
          return;
      }
    }
    saveData();
    return;

    function verifyLocaleTranslationInputs() {
      const allLocalizedTranslationsClone = _.cloneDeep(
        allLocalizedTranslations,
      );
      let hasErrors = false;
      let firstNamespaceWithError = LocalizationNamespace.Tag;

      Object.entries(allLocalizedTranslationsClone).forEach(
        ([namespace, localizedTranslations]) => {
          Object.entries(localizedTranslations).forEach(
            ([localeCode, localeTranslations]) => {
              localeTranslations.forEach((localeTranslation, index) => {
                if (
                  localeCode === defaultLocale.code &&
                  !localeTranslation.translation &&
                  !localeTranslation.deleted
                ) {
                  // Show an error if translation for the default locale is empty
                  localeTranslation.errors = strings.errors.missingInput; // Currently using an arbitrary error string here to indicate input is empty
                  if (!hasErrors) {
                    firstNamespaceWithError =
                      namespace as LocalizationNamespace;
                    hasErrors = true;
                  }
                } else if (!localeTranslation.translation) {
                  // Set translations of empty non-default locales to default locale translations
                  localeTranslation.translation =
                    localizedTranslations[defaultLocale.code][
                      index
                    ].translation;
                }
              });
            },
          );
        },
      );
      setAllLocalizedTranslations(allLocalizedTranslationsClone);
      return {
        errors: hasErrors
          ? {
              namespace: firstNamespaceWithError as LocalizationNamespace,
              message: strings.errors.missingInput,
            }
          : undefined,
      };
    }

    function saveData() {
      Object.values(LocalizationNamespace).forEach((namespace) => {
        locales.forEach(({ code }) => {
          if (
            !_.isEqual(
              allLocalizedTranslations[namespace][code],
              allLocalizedTranslationsOriginal[namespace][code],
            )
          ) {
            const params = { appId, environment, namespace, localeCode: code };
            // Note: Only System Admins can use _SetLocalizedTranslations_, which allows adding new keys,
            // while App Admins can only update the translations of corresponding keys.
            isSysAdmin
              ? mutateSetLocalizedTranslations({
                  ...params,
                  input: {
                    allTranslations:
                      sanitizeExtendedUpdateLocalizedTranslationInputs(
                        allLocalizedTranslations[namespace][code],
                      ),
                  },
                })
              : mutateUpdateLocalizedTranslations({
                  ...params,
                  input: {
                    updates: sanitizeExtendedUpdateLocalizedTranslationInputs(
                      allLocalizedTranslations[namespace][code],
                    ),
                  },
                });
          }
        });
      });
    }
  }, [
    allLocalizedTranslations,
    allLocalizedTranslationsOriginal,
    appId,
    defaultLocale.code,
    environment,
    isSysAdmin,
    locales,
    mutateSetLocalizedTranslations,
    mutateUpdateLocalizedTranslations,
    navigate,
  ]);

  const handleOnCancelChanges = React.useCallback(() => {
    setAllLocalizedTranslations(allLocalizedTranslationsOriginal);
    setIsTouched(false);
    clearErrors();
  }, [allLocalizedTranslationsOriginal]);

  const handleOnAddLocalizedTranslation = React.useCallback(() => {
    const { errors } = verifyLocaleTranslationKey();
    if (errors.length) {
      setAddTranslationKeyError(true);
      setErrorMessage(errors[0]);
      return;
    }
    const currLocaleTranslations =
      allLocalizedTranslations[currLocalizationNamespace][selectedLocale.code];
    setAllLocalizedTranslations({
      ...allLocalizedTranslations,
      [currLocalizationNamespace]: {
        [selectedLocale.code]: [
          ...currLocaleTranslations,
          { key: newLocalizedTranslationKey, translation: '' },
        ],
      },
    });
    setIsTouched(true);
    clearErrors();
    setNewLocalizedTranslationKey('');
    return;

    function verifyLocaleTranslationKey() {
      const duplicateKeys = allLocalizedTranslations[currLocalizationNamespace][
        selectedLocale.code
      ].some(({ key }) => key === newLocalizedTranslationKey);
      const containsOnlyAlphanumericCharacters =
        newLocalizedTranslationKey.match(new RegExp(ALPHANUMERIC, 'g'))
          ?.length === newLocalizedTranslationKey.length;

      const errors = [];
      if (!newLocalizedTranslationKey) {
        errors.push(strings.errors.missingInput);
      }
      if (duplicateKeys) {
        errors.push(strings.errors.duplicateKeys);
      }
      if (!containsOnlyAlphanumericCharacters) {
        errors.push(strings.errors.onlyAlphanumeric);
      }

      return { errors };
    }
  }, [
    allLocalizedTranslations,
    currLocalizationNamespace,
    newLocalizedTranslationKey,
    selectedLocale.code,
  ]);

  const handleOnUpdateLocalizedTranslation = React.useCallback(
    (key: string, value: string) => {
      setIsTouched(true);
      const currLocaleTranslations =
        allLocalizedTranslations[currLocalizationNamespace][
          selectedLocale.code
        ];
      setAllLocalizedTranslations({
        ...allLocalizedTranslations,
        [currLocalizationNamespace]: {
          ...allLocalizedTranslations[currLocalizationNamespace],
          [selectedLocale.code]: currLocaleTranslations.map(
            (localeTranslation) => {
              if (localeTranslation.key === key) {
                return {
                  ...localeTranslation,
                  translation: value,
                };
              } else {
                return localeTranslation;
              }
            },
          ),
        },
      });
    },
    [allLocalizedTranslations, currLocalizationNamespace, selectedLocale.code],
  );

  const handleOnDeleteTranslation = () => {
    const allLocalizedTranslationsClone = _.cloneDeep(allLocalizedTranslations);
    locales.forEach(({ code }) => {
      allLocalizedTranslationsClone[currLocalizationNamespace][code].forEach(
        (localeTranslation) => {
          if (localeTranslation.key === keyToDelete) {
            localeTranslation.deleted = true;
          }
        },
      );
    });
    setAllLocalizedTranslations(allLocalizedTranslationsClone);
    setKeyToDelete('');
    setIsTouched(true);
  };

  function initializeInputs(data: LocalizedTranslationsQuery) {
    const localeTranslations = data.localizedTranslations.map(
      (localizedTranslation) => ({
        key: localizedTranslation.key,
        translation: localizedTranslation.value,
        environment: environment,
      }),
    );
    const originalLocaleTranslations =
      allLocalizedTranslationsOriginal[currLocalizationNamespace][
        selectedLocale.code
      ];
    const currLocaleTranslations =
      allLocalizedTranslations[currLocalizationNamespace][selectedLocale.code];

    const originalEnvChanged =
      originalLocaleTranslations.length &&
      originalLocaleTranslations[0].environment !== environment;
    const currEnvChanged =
      currLocaleTranslations.length &&
      currLocaleTranslations[0].environment !== environment;

    if (
      originalEnvChanged ||
      !_.isEqual(originalLocaleTranslations, localeTranslations)
    ) {
      setAllLocalizedTranslationsOriginal({
        ...allLocalizedTranslationsOriginal,
        [currLocalizationNamespace]: {
          ...allLocalizedTranslationsOriginal[currLocalizationNamespace],
          [selectedLocale.code]: localeTranslations,
        },
      });
      if (
        currEnvChanged ||
        !isTouched ||
        _.isEqual(originalLocaleTranslations, currLocaleTranslations)
      ) {
        // Update the current translations if user is not in the middle of editing
        setAllLocalizedTranslations({
          ...allLocalizedTranslations,
          [currLocalizationNamespace]: {
            ...allLocalizedTranslations[currLocalizationNamespace],
            [selectedLocale.code]: localeTranslations,
          },
        });
      }
    }
  }

  const renderEmptyLocalizedTranslations = () => {
    return (
      <Center height="100%" flexDirection="column" gap="15px">
        <Image src={bookmarkIcon} />
        <Text textStyle="subtitle3" color="neutrals.brandGrey.500">
          {strings.tags.emptyLocalizedTranslationMessage}
        </Text>
      </Center>
    );
  };

  const renderUpdateButtons = React.useCallback(() => {
    return (
      <HStack spacing="25px" justifyContent="flex-end">
        <SecondaryButton
          label={strings.common.cancel}
          onClick={handleOnCancelChanges}
          isDisabled={!isTouched || isProdEnv}
        />
        <PrimaryButton
          label={strings.common.saveChanges}
          onClick={handleOnSave}
          isDisabled={!isTouched || isProdEnv}
        />
      </HStack>
    );
  }, [handleOnCancelChanges, handleOnSave, isProdEnv, isTouched]);

  const renderAddLocalizedTranslation = React.useCallback(() => {
    return (
      <Box padding="40px" overflow="auto">
        <RowInput
          label={strings.tags.stringKey}
          inputProps={{
            value: newLocalizedTranslationKey,
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              const sanitizedValue =
                e.target.value.match(new RegExp(ALPHANUMERIC, 'g'))?.join('') ??
                '';
              setNewLocalizedTranslationKey(sanitizedValue);
            },
            isInvalid: !!addTranslationKeyError,
            isDisabled: isProdEnv,
          }}
          actionElement={
            <CreateButton
              label={strings.common.add}
              onClick={handleOnAddLocalizedTranslation}
              isDisabled={isProdEnv}
            />
          }
          containerStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '30px',
          }}
        />
      </Box>
    );
  }, [
    newLocalizedTranslationKey,
    addTranslationKeyError,
    isProdEnv,
    handleOnAddLocalizedTranslation,
  ]);

  const renderDeleteIconButton = React.useCallback(
    (key: string, deleted?: boolean) => {
      return (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            variant="iconButton"
            aria-label={
              deleted ? strings.tags.ariaTagDeleted : strings.tags.ariaDeleteTag
            }
            isRound
            backgroundColor="transparent"
            onClick={() => setKeyToDelete(key)}
            isDisabled={deleted || isProdEnv}
          >
            <Image src={deleted ? trashedIcon : trashIcon} />
          </IconButton>
        </Box>
      );
    },
    [isProdEnv],
  );

  const renderUpdateLocalizedTranslation = React.useCallback(() => {
    return (
      <Box
        layerStyle="updateLocalizedTranslations"
        width={isSysAdmin ? '726px' : '644px'}
      >
        <VStack spacing="40px">
          {allLocalizedTranslations[currLocalizationNamespace][
            selectedLocale.code
          ]?.map(({ key, translation, errors, deleted }) => (
            <RowInput
              key={`${selectedLocale.code}-${key}`}
              label={key}
              inputProps={{
                value: translation,
                onChange: (e) => {
                  handleOnUpdateLocalizedTranslation(key, e.target.value);
                },
                isInvalid: !!errors,
                isDisabled: deleted || isProdEnv,
              }}
              actionElement={isSysAdmin && renderDeleteIconButton(key, deleted)}
              containerStyle={{
                display: 'flex',
                alignItems: 'center',
                gap: '30px',
              }}
            />
          ))}
        </VStack>
        {!isProdEnv && renderUpdateButtons()}
      </Box>
    );
  }, [
    isSysAdmin,
    allLocalizedTranslations,
    currLocalizationNamespace,
    selectedLocale.code,
    renderUpdateButtons,
    isProdEnv,
    renderDeleteIconButton,
    handleOnUpdateLocalizedTranslation,
  ]);

  const renderTab = React.useCallback(() => {
    return (
      <Box>
        {isSysAdmin && renderAddLocalizedTranslation()}
        <LocalesTabList
          listType="detail"
          entries={locales}
          selectedLocale={selectedLocale}
          setSelectedLocale={setSelectedLocale}
        >
          {allLocalizedTranslations[currLocalizationNamespace][
            selectedLocale.code
          ].length
            ? renderUpdateLocalizedTranslation()
            : renderEmptyLocalizedTranslations()}
        </LocalesTabList>
      </Box>
    );
  }, [
    isSysAdmin,
    renderAddLocalizedTranslation,
    locales,
    selectedLocale,
    allLocalizedTranslations,
    currLocalizationNamespace,
    renderUpdateLocalizedTranslation,
  ]);

  const tabsData: TabData[] = useMemo(
    () => [
      {
        label: strings.tags.tags,
        content: renderTab(),
        path: routes.appTags({ appId }),
      },
      {
        label: strings.tags.badges,
        content: renderTab(),
        path: routes.appTagsBadges({ appId }),
      },
      {
        label: strings.tags.tuneIn,
        content: renderTab(),
        path: routes.appTagsTuneIn({ appId }),
      },
    ],
    [appId, renderTab],
  );

  if (localizedTranslationsQuery.isError)
    throw parsedRequestError(localizedTranslationsQuery.error);

  return (
    <Page
      title={strings.tags.tags}
      withEnvironmentBar
      isLoading={localizedTranslationsQuery.isLoading}
      isForm
      pageRef={pageRef}
      withEnvironmentPrompt
      withPublishPrompt
      environmentBarDirtyFormCheck={() =>
        !fastDeepEqual(
          allLocalizedTranslations,
          allLocalizedTranslationsOriginal,
        )
      }
    >
      <Form hasError={false}>
        <FormError message={errorMessage} />
        <FormPrompt
          isFormDirty={
            !fastDeepEqual(
              allLocalizedTranslations,
              allLocalizedTranslationsOriginal,
            )
          }
          moreExactCheck={[
            (_, nextLocation) =>
              !['tune-in', 'badge', 'tags'].some((key) =>
                nextLocation.pathname.includes(key),
              ),
          ]}
        />
        <Tabs data={tabsData} spacing="70px" onChange={clearErrors} />
      </Form>
      <StandardDeletionDialog
        itemType={strings.tags.string}
        isOpen={!!keyToDelete}
        onClose={() => setKeyToDelete('')}
        onDelete={handleOnDeleteTranslation}
        isLoading={setLocalizedTranslationsMutation.isLoading}
        message={strings.tags.deleteKeyMessage}
      />
    </Page>
  );
}
