import React, {
  Fragment,
  useContext,
  useReducer,
  useRef,
  useState,
} from 'react';
import fastDeepEqual from 'fast-deep-equal';
import Form, {
  FormButtons,
  FormDivider,
  FormError,
  FormSectionWithImage,
} from '../../common/Form';
import ColorInput from '../../common/inputs/ColorInput';
import ImageInput from '../../common/inputs/ImageInput';
import Page, { pagePaddingX } from '../../common/Page';
import textUsage from '../../../assets/text-usage.svg';
import buttonUsage from '../../../assets/button-usage.svg';
import textInputUsage from '../../../assets/text-input-usage.svg';
import badgeUsage from '../../../assets/badge-usage.svg';
import navigationUsage from '../../../assets/navigation-usage.svg';
import backgroundUsage from '../../../assets/background-usage.svg';
import cardUsage from '../../../assets/card-usage.svg';
import { validateColor } from '../../../utils/validation';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ActionType, RequestError } from '../../../types';
import { useParams } from 'react-router-dom';
import { EnvironmentContext } from '../../../contexts';
import { parsedRequestError } from '../../../utils/errors';
import { appLogoHeight } from '../../../utils/images';
import {
  AppThemeQuery,
  AppThemeQueryVariables,
  CreateThemeColorsInput,
  Environment,
  ImageType,
  UpdateThemeMutation,
  UpdateThemeMutationVariables,
} from '../../../gql/gqlRequests';
import { appThemeRequest, updateThemeRequest } from '../../../support/theme';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { strings } from '../../../utils/strings';
import {
  maxThemeGuideImageWidth,
  navBarWidth,
} from '../../../themes/constants';
import { scrollToTop } from '../../../utils';
import FormPrompt from '../../common/FormPrompt';
const smallThemePageBreakPoint = 1150;

// sections

type InputField = { name: string; key: keyof InputState };
type Section = { fields: InputField[]; imageSrc: string; link?: string };

const textFields: InputField[] = [
  { name: strings.theme.text, key: 'text' },
  { name: strings.theme.textSecondary, key: 'textSecondary' },
];

const buttonFields: InputField[] = [
  { name: strings.theme.button, key: 'button' },
  { name: strings.theme.buttonBorder, key: 'buttonBorder' },
  { name: strings.theme.buttonFocused, key: 'buttonFocused' },
  { name: strings.theme.buttonFocusedBorder, key: 'buttonFocusedBorder' },
  { name: strings.theme.buttonText, key: 'buttonText' },
  { name: strings.theme.buttonTextFocused, key: 'buttonTextFocused' },
];

const textInputFields: InputField[] = [
  { name: strings.theme.textInput, key: 'textInput' },
  { name: strings.theme.textInputBorder, key: 'textInputBorder' },
  { name: strings.theme.textInputFocused, key: 'textInputFocused' },
  { name: strings.theme.textInputFocusedBorder, key: 'textInputFocusedBorder' },
];

const badgeFields: InputField[] = [
  { name: strings.theme.badge, key: 'badge' },
  { name: strings.theme.tag, key: 'tag' },
  { name: strings.theme.progress, key: 'progress' },
  { name: strings.theme.underline, key: 'underline' },
];

const navigationFields: InputField[] = [
  { name: strings.theme.navBarBackground, key: 'navBarBackground' },
  { name: strings.theme.separator, key: 'separator' },
];

const backgroundFields: InputField[] = [
  { name: strings.theme.background, key: 'background' },
  { name: strings.theme.overlay, key: 'overlay' },
  { name: strings.theme.loadingIndicator, key: 'loadingIndicator' },
];

const cardFields: InputField[] = [
  { name: strings.theme.cardFocusedBorder, key: 'cardFocusedBorder' },
  { name: strings.theme.cardPlaceholder, key: 'cardPlaceholder' },
];

const sections: Section[] = [
  { fields: textFields, imageSrc: textUsage },
  { fields: buttonFields, imageSrc: buttonUsage },
  { fields: textInputFields, imageSrc: textInputUsage },
  {
    fields: badgeFields,
    imageSrc: badgeUsage,
    // TODO EFS-424 (v1.1): re-introduce learn more link
    // link: externalLinks.theme.learnMore,
  },
  { fields: navigationFields, imageSrc: navigationUsage },
  { fields: backgroundFields, imageSrc: backgroundUsage },
  { fields: cardFields, imageSrc: cardUsage },
];

function capitalizeColors(state: InputState) {
  Object.keys(initialInputState).forEach((keyString) => {
    const key = keyString as keyof InputState;
    if (
      ![
        'logoKey',
        'premiumIconKey',
        'landscapeFallbackKey',
        'squareFallbackKey',
        'portraitFallbackKey',
      ].includes(key)
    ) {
      state[key] = state[key].toUpperCase();
    }
  });
}

// reducer

type Action =
  | { type: ActionType.RESET }
  | { type: ActionType.SET_ALL; state: InputState }
  | {
      type: ActionType.SET_STRING;
      key: keyof InputState;
      value: string;
    };

type InputState = CreateThemeColorsInput & {
  logoKey: string;
  premiumIconKey: string;
  landscapeFallbackKey: string;
  portraitFallbackKey: string;
  squareFallbackKey: string;
};

function inputReducer(state: InputState, action: Action) {
  const updatedState = { ...state };
  switch (action.type) {
    case ActionType.SET_ALL:
      return { ...action.state };
    case ActionType.RESET:
      return initialInputState;

    case ActionType.SET_STRING:
      updatedState[action.key] = action.value;
      return updatedState;

    case ActionType.SET_ALL:
      return action.state;

    default:
      throw new Error('Unknown theme input state action');
  }
}

const initialInputState = {
  text: '',
  textSecondary: '',
  button: '',
  buttonBorder: '',
  buttonFocused: '',
  buttonFocusedBorder: '',
  buttonText: '',
  buttonTextFocused: '',
  textInput: '',
  textInputBorder: '',
  textInputFocused: '',
  textInputFocusedBorder: '',
  badge: '',
  tag: '',
  progress: '',
  underline: '',
  navBarBackground: '',
  separator: '',
  background: '',
  overlay: '',
  loadingIndicator: '',
  cardFocusedBorder: '',
  cardPlaceholder: '',
  logoKey: '',
  premiumIconKey: '',
  landscapeFallbackKey: '',
  portraitFallbackKey: '',
  squareFallbackKey: '',
};

// component

export default function ThemeForm() {
  const pageRef = useRef<HTMLDivElement>(null);
  const { environment } = useContext(EnvironmentContext);
  const params = useParams();
  const appId = params.appId ?? '';

  const [inputState, dispatchInputState] = useReducer(
    inputReducer,
    initialInputState,
  );

  const buildInitialData = (data: AppThemeQuery) => {
    const {
      colors,
      logo,
      premiumIcon,
      landscapeFallback,
      portraitFallback,
      squareFallback,
    } = data.app.theme;
    const preparedData = {
      ...colors,
      logoKey: logo.key,
      premiumIconKey: premiumIcon?.key ?? '',
      landscapeFallbackKey: landscapeFallback?.key ?? '',
      portraitFallbackKey: portraitFallback?.key ?? '',
      squareFallbackKey: squareFallback?.key ?? '',
    };
    capitalizeColors(preparedData);
    dispatchInputState({
      type: ActionType.SET_ALL,
      state: preparedData,
    });
  };

  // fetch

  const themeQueryFn = useAuthRequest<AppThemeQueryVariables, AppThemeQuery>(
    appThemeRequest,
  );
  const themeQuery = useQuery<AppThemeQuery, RequestError>({
    // env as a key forces refetch when env changes
    queryKey: ['theme', environment],
    queryFn: () => themeQueryFn({ appId, environment }),
    onSuccess: buildInitialData,
    // don't refetch; would overwrite any changes user is making to inputs
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // errors

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  function showErrorAndScrollToTop() {
    setErrorMessage(strings.errors.missingInput);
    scrollToTop(pageRef);
  }

  function showSuccessAndScrollToTop() {
    setErrorMessage('');
    setSuccessMessage(strings.success.saved);
    scrollToTop(pageRef);
    const clearSuccessMessage = setTimeout(() => {
      setSuccessMessage('');
      clearTimeout(clearSuccessMessage);
    }, 4000);
    themeQuery.refetch();
  }

  // update

  const updateThemeMutationFn = useAuthRequest<
    UpdateThemeMutationVariables,
    UpdateThemeMutation
  >(updateThemeRequest);
  const updateThemeMutation = useMutation<
    UpdateThemeMutation,
    RequestError,
    UpdateThemeMutationVariables
  >({
    mutationFn: updateThemeMutationFn,
    onSuccess: showSuccessAndScrollToTop,
    onError: showErrorAndScrollToTop,
  });

  function tryToUpdateTheme(isInputValid: boolean, inputState: InputState) {
    if (isInputValid) {
      updateTheme(inputState);
    } else {
      showErrorAndScrollToTop();
    }
  }

  function updateTheme(inputState: InputState) {
    const themeId = themeQuery.data?.app.theme.id ?? '';
    const {
      landscapeFallbackKey,
      squareFallbackKey,
      portraitFallbackKey,
      logoKey,
      premiumIconKey,
      ...colors
    } = inputState;
    const input = {
      colors,
      logoKey,
      premiumIconKey,
      landscapeFallbackKey: !landscapeFallbackKey ? null : landscapeFallbackKey,
      squareFallbackKey: !squareFallbackKey ? null : squareFallbackKey,
      portraitFallbackKey: !portraitFallbackKey ? null : portraitFallbackKey,
    };
    updateThemeMutation.mutate({ id: themeId, environment, input });
  }

  // validation

  // note: if a color input is wrong, the backend error will just indicate
  // `color` rather than the particular input, so nothing will be highlighted
  // - but the frontend should handle this before the backend has a chance so it's fine

  // UI

  const isProdEnv = environment === Environment.Production;
  // when env changes, query is `fetching` but not `loading`, and
  // we still want the UI to be disabled
  const isFetchingOrLoading =
    themeQuery.isFetching || updateThemeMutation.isLoading;

  if (themeQuery.isError) throw parsedRequestError(themeQuery.error);

  if (themeQuery.data) {
    const {
      colors,
      logo,
      premiumIcon,
      landscapeFallback,
      portraitFallback,
      squareFallback,
    } = themeQuery.data.app.theme;
    const preparedData = {
      ...colors,
      logoKey: logo.key,
      premiumIconKey: premiumIcon?.key ?? '',
      landscapeFallbackKey: landscapeFallback?.key ?? '',
      portraitFallbackKey: portraitFallback?.key ?? '',
      squareFallbackKey: squareFallback?.key ?? '',
    };
    capitalizeColors(preparedData);

    return (
      <ThemeFormRender
        inputState={inputState}
        dispatch={dispatchInputState}
        isFormDirty={!fastDeepEqual(inputState, preparedData)}
        successMessage={successMessage}
        errorMessage={errorMessage}
        tryToUpdateTheme={tryToUpdateTheme}
        onNegativeClick={() => {
          themeQuery.refetch();
          setErrorMessage('');
        }}
        isLoading={isFetchingOrLoading}
        isProdEnv={isProdEnv}
        pageRef={pageRef}
      />
    );
  }

  return null;
}

type ThemeFormRenderProps = {
  inputState: InputState;
  errorMessage: string;
  successMessage: string;
  isFormDirty: boolean;
  dispatch: (value: Action) => void;
  tryToUpdateTheme: (isInputValid: boolean, inputState: InputState) => void;
  onNegativeClick: () => void;
  isLoading: boolean;
  isProdEnv: boolean;
  pageRef: React.RefObject<HTMLDivElement>;
};

function ThemeFormRender({
  inputState,
  errorMessage,
  successMessage,
  isFormDirty,
  dispatch,
  tryToUpdateTheme,
  onNegativeClick,
  isLoading,
  isProdEnv,
  pageRef,
}: ThemeFormRenderProps) {
  const isValidationActive = !!errorMessage;

  function validate(key: keyof InputState) {
    switch (key) {
      case 'logoKey':
        return inputState.logoKey !== '';

      case 'premiumIconKey':
        return inputState.premiumIconKey !== '';

      case 'landscapeFallbackKey':
      case 'portraitFallbackKey':
      case 'squareFallbackKey':
        return true;

      default:
        return validateColor(inputState[key]);
    }
  }

  const isInputValid = Object.keys(inputState).every((key) =>
    validate(key as keyof InputState),
  );

  return (
    <Page
      isForm
      withEnvironmentBar
      title={strings.theme.theme}
      subtitle={strings.theme.customizeTheme}
      isLoading={isLoading}
      pageRef={pageRef}
      withEnvironmentPrompt
      withPublishPrompt
      environmentBarDirtyFormCheck={() => isFormDirty}
    >
      <Form hasError={!!errorMessage}>
        <FormPrompt isFormDirty={isFormDirty} />
        <FormError
          message={!errorMessage ? successMessage : errorMessage}
          success={!!successMessage}
        />
        {sections.map(({ fields, imageSrc, link }) => (
          <ColorSection
            key={fields[0].key}
            input={inputState}
            fields={fields}
            imageSrc={imageSrc}
            link={link}
            isLoading={isLoading}
            canEdit={!isProdEnv}
            isValidationActive={isValidationActive}
            validate={validate}
            dispatch={dispatch}
          />
        ))}

        <ImageInput
          label={strings.theme.logo}
          description={strings.theme.logoDescription({ appLogoHeight })}
          hasRequiredStar
          imageType={ImageType.Standard}
          value={inputState.logoKey}
          onChange={(value) =>
            dispatch({
              type: ActionType.SET_STRING,
              key: 'logoKey',
              value,
            })
          }
          isInvalid={isValidationActive && !validate('logoKey')}
          isDisabled={isLoading || isProdEnv}
        />
        <ImageInput
          label={strings.theme.premiumIcon}
          description={strings.theme.premiumIconDescription}
          imageType={ImageType.Standard}
          hasRequiredStar
          value={inputState.premiumIconKey}
          onChange={(value) =>
            dispatch({
              type: ActionType.SET_STRING,
              key: 'premiumIconKey',
              value,
            })
          }
          isInvalid={isValidationActive && !validate('premiumIconKey')}
          isDisabled={isLoading || isProdEnv}
        />
        <FormDivider />
        <ImageInput
          label={strings.theme.landscapeFallbackTitle}
          description={strings.theme.tileFallbackDescription({
            width: '365',
            height: '206',
          })}
          imageType={ImageType.Background}
          value={inputState.landscapeFallbackKey}
          onChange={(value) =>
            dispatch({
              type: ActionType.SET_STRING,
              key: 'landscapeFallbackKey',
              value,
            })
          }
          isDisabled={isLoading || isProdEnv}
        />
        <ImageInput
          label={strings.theme.portraitFallbackTitle}
          description={strings.theme.tileFallbackDescription({
            width: '230',
            height: '337',
          })}
          imageType={ImageType.Background}
          value={inputState.portraitFallbackKey}
          onChange={(value) =>
            dispatch({
              type: ActionType.SET_STRING,
              key: 'portraitFallbackKey',
              value,
            })
          }
          isDisabled={isLoading || isProdEnv}
        />
        <ImageInput
          label={strings.theme.squareFallbackTitle}
          description={strings.theme.tileFallbackDescription({
            width: '230',
            height: '230',
          })}
          imageType={ImageType.Background}
          value={inputState.squareFallbackKey}
          onChange={(value) =>
            dispatch({
              type: ActionType.SET_STRING,
              key: 'squareFallbackKey',
              value,
            })
          }
          isDisabled={isLoading || isProdEnv}
        />

        {!isProdEnv && (
          <FormButtons
            positiveLabel={strings.common.saveChanges}
            positiveOnClick={() => tryToUpdateTheme(isInputValid, inputState)}
            positiveIsDisabled={isLoading}
            negativeLabel={strings.common.discardChanges}
            negativeOnClick={onNegativeClick}
            negativeIsDisabled={isLoading}
          />
        )}
      </Form>
    </Page>
  );
}

type ColorSectionProps = {
  input: InputState;
  fields: InputField[];
  imageSrc: string;
  link?: string;
  isLoading?: boolean;
  canEdit?: boolean;
  isValidationActive?: boolean;
  validate: (key: keyof InputState) => boolean;
  dispatch: (value: Action) => void;
};

function ColorSection({
  input,
  fields,
  imageSrc,
  link,
  isLoading = false,
  canEdit = false,
  isValidationActive = true,
  validate,
  dispatch,
}: ColorSectionProps) {
  const { width } = useWindowSize();
  const shouldShowImage = width >= smallThemePageBreakPoint;
  const imageWidth = shouldShowImage
    ? width - parseInt(pagePaddingX) - parseInt(navBarWidth)
    : 0;

  return (
    <Fragment>
      <FormSectionWithImage
        imageSrc={imageSrc}
        imageWidth={`${
          imageWidth > parseInt(maxThemeGuideImageWidth)
            ? maxThemeGuideImageWidth
            : imageWidth
        }px`}
        link={link}
      >
        {fields.map(({ name, key }) => (
          <ColorInput
            key={key}
            label={name}
            isDisabled={isLoading}
            isPermanentlyDisabled={!canEdit}
            value={input[key]}
            isInvalid={isValidationActive && !validate(key)}
            setSanitizedValue={(value) =>
              dispatch({
                type: ActionType.SET_STRING,
                key,
                value,
              })
            }
          />
        ))}
      </FormSectionWithImage>
      <FormDivider />
    </Fragment>
  );
}
