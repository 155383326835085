import React, { useContext, useMemo } from 'react';
import Page from '../../common/Page';
import { createColumnHelper } from '@tanstack/react-table';
import {
  AppsStatusesQuery,
  AppsStatusesQueryVariables,
  Role,
  Status,
} from '../../../gql/gqlRequests';
import StandardTable from '../../common/tables/StandardTable';
import { EnvironmentContext, UserContext } from '../../../contexts';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { appsStatusesRequest } from '../../../support/apps';
import { useQuery } from '@tanstack/react-query';
import { RequestError } from '../../../types';
import { displayEnum } from '../../../utils';
import { parsedRequestError } from '../../../utils/errors';
import Link from '../../common/Link';
import { routes } from '../../../types/routes';
import moment from 'moment';
import { strings } from '../../../utils/strings';

const DATE_FORMAT = 'DD/MM/yyyy HH:mm';

type StatusRow = {
  id: string;
  account: string;
  app: string;
  appId: string;
  environment: string;
  lastSuccessfulDate?: string;
  status: Status;
  date: string;
  createdAt: Date;
};

const columnHelper = createColumnHelper<StatusRow>();

export default function PublishingStatus() {
  const { environment } = useContext(EnvironmentContext);
  const { loggedInUser } = useContext(UserContext);

  const appsStatusesQueryFn = useAuthRequest<
    AppsStatusesQueryVariables,
    AppsStatusesQuery
  >(appsStatusesRequest);

  const appsStatusesQuery = useQuery<AppsStatusesQuery, RequestError>({
    queryKey: ['statuses', environment],
    queryFn: () => appsStatusesQueryFn({ environment }),
  });

  const columns = useMemo(() => {
    const columns = [
      columnHelper.accessor('id', {}),
      columnHelper.accessor('createdAt', {}),
      columnHelper.accessor('app', {
        header: 'App',
        cell: (info) => (
          <Link
            label={info.getValue()}
            to={routes.appDetail({ appId: info.row.original.appId })}
          />
        ),
      }),
      columnHelper.accessor('environment', {
        header: 'Environment',
        cell: (info) => displayEnum(info.getValue()),
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => displayEnum(info.getValue()),
      }),
      columnHelper.accessor('date', {
        header: 'Date',
        cell: (info) => (
          <Link label={info.getValue()} to={info.row.original.id} />
        ),
      }),
      columnHelper.accessor('lastSuccessfulDate', {
        header: 'Last Successful Date',
        cell: (info) => info.getValue(),
      }),
    ];
    return loggedInUser.role === Role.SystemAdmin
      ? [columnHelper.accessor('account', { header: 'Account' }), ...columns]
      : columns;
  }, [loggedInUser]);

  const appsStatusesData = useMemo(
    () =>
      (appsStatusesQuery.data?.getAllAppsStatuses ?? []).map(
        ({
          account,
          app,
          createdAt,
          environment,
          id,
          status,
          lastSuccessfulDate,
          appId,
        }) => ({
          id,
          account,
          app,
          appId,
          environment,
          status,
          date: moment(createdAt).format(DATE_FORMAT),
          createdAt,
          lastSuccessfulDate: !!lastSuccessfulDate
            ? moment(lastSuccessfulDate).format(DATE_FORMAT)
            : '-',
        }),
      ),
    [appsStatusesQuery.data],
  );

  if (appsStatusesQuery.isError)
    throw parsedRequestError(appsStatusesQuery.error);

  return (
    <Page
      title="App Status"
      withEnvironmentBar
      isLoading={appsStatusesQuery.isLoading}
    >
      <StandardTable
        title={strings.apps.allApps}
        isLoading={appsStatusesQuery.isLoading}
        data={appsStatusesData}
        columns={columns}
        boldColumn="app"
      />
    </Page>
  );
}
