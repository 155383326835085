import { Box, Center, Image as ChakraImage, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import Button from '../buttons/Button';
import { Image } from '../../../gql/gqlRequests';
import { testIds } from '../../../utils/testIds';
import { strings } from '../../../utils/strings';

type ImageLibraryTileProps = {
  image: Image;
  isFocused: boolean;
  onFocus: () => void;
  onUnfocus: () => void;
  onPreview: () => void;
};

export default function ImageLibraryTile({
  image,
  isFocused,
  onFocus,
  onUnfocus,
  onPreview,
}: ImageLibraryTileProps) {
  const [isHovering, setIsHovering] = useState(false);

  const handleClick = isFocused ? onUnfocus : onFocus;

  return (
    <Box>
      <Box
        position="relative"
        onClick={handleClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        cursor="pointer"
        data-testid={testIds.image_tile}
        backgroundColor="neutrals.brandGrey.400"
        borderRadius="10px"
        height="190px"
      >
        <Center height="100%">
          <ChakraImage
            src={image.url}
            alt={image.filename}
            layerStyle="imageLibraryTile"
          />
        </Center>
        <Center
          layerStyle="imageLibraryTileOverlay"
          borderColor={
            isFocused ? 'secondary.secondaryBlue.500' : 'transparent'
          }
          backgroundColor={isHovering ? 'neutrals.black50percent' : undefined}
        >
          {isHovering && (
            <Button
              // unique button
              variant="preview"
              label={strings.common.preview}
              onClick={onPreview}
            />
          )}
        </Center>
      </Box>
      <Text textAlign={'center'} paddingTop={3}>
        {image.filename}
      </Text>
    </Box>
  );
}
