import React, { Fragment, useContext } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { Role } from '../../gql/gqlRequests';
import { AppContext, UserContext } from '../../contexts';
import NavigationEntries from './NavigationEntries';
import NavigationProfile from './NavigationProfile';
import NavigationApp from './NavigationApp';
import Progress from '../common/Progress';
import { strings } from '../../utils/strings';

const topOfTextWithApp = '35px';
const topOfTextWithoutApp = '75px';
const spaceBetweenTextAndSpinner = '25px';

export default function Navigation() {
  const { loggedInUser } = useContext(UserContext);
  const { appId, isLoading: isAppLoading } = useContext(AppContext);
  const isAnAppSelected = !!appId;
  const shouldShowAppInfo =
    (loggedInUser.role === Role.SystemAdmin && isAnAppSelected) ||
    (loggedInUser.role === Role.AppAdmin && loggedInUser.appIds.length > 1);

  const topOfText = shouldShowAppInfo ? topOfTextWithApp : topOfTextWithoutApp;

  /* Those values are based on Figma design but dont match them because the logo text box is bigger in Flow app.
   * But they are set accordingly to match the total height of logo box.
   */
  const flowLogoPaddingBottom = shouldShowAppInfo ? '29px' : '50px';

  return (
    <Box layerStyle="navigationContainer">
      <Box
        paddingTop={topOfText}
        width="100%"
        textAlign="center"
        paddingBottom={flowLogoPaddingBottom}
      >
        <Text
          fontFamily="DIN condensed"
          fontSize="40px"
          fontWeight="700"
          lineHeight={1}
        >
          {strings.common.flow}
        </Text>
      </Box>

      {isAppLoading ? (
        <Center paddingTop={spaceBetweenTextAndSpinner}>
          <Progress size="40px" />
        </Center>
      ) : (
        <Fragment>
          {shouldShowAppInfo && <NavigationApp />}
          <NavigationEntries />
        </Fragment>
      )}

      <NavigationProfile />
    </Box>
  );
}
