import React from 'react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import Navigation from './components/navigation/Navigation';
import RequiresAuthentication from './components/common/RequiresAuthentication';
import ContextsProvider from './components/common/ContextsProvider';
import SystemAdminOnly from './components/common/SystemAdminOnly';
import WaitForApp from './components/common/WaitForApp';
import Landing from './components/pages/Landing';
import Login from './components/pages/authentication/Login';
import Register from './components/pages/authentication/Register';
import ForgotPassword from './components/pages/authentication/ForgotPassword';
import CreateNewPassword from './components/pages/authentication/CreateNewPassword';
import Accounts from './components/pages/accounts/Accounts';
import AccountForm from './components/pages/accounts/AccountForm';
import Apps from './components/pages/apps/Apps';
import AppDetail from './components/pages/apps/AppDetail';
import AppForm from './components/pages/apps/AppForm';
import Feeds from './components/pages/feeds/Feeds';
import FeedDetail from './components/pages/feeds/FeedDetail';
import FeedForm from './components/pages/feeds/FeedForm';
import Screens from './components/pages/screens/Screens';
import ScreenForm from './components/pages/screens/ScreenForm';
import Platforms from './components/pages/platforms/Platforms';
import Platform from './components/pages/platforms/Platform';
import PlatformConfigForm from './components/pages/platforms/PlatformConfigForm';
import NavBarItemForm from './components/pages/platforms/NavBarItemForm';
import Users from './components/pages/users/Users';
import UserDetail from './components/pages/users/UserDetail';
import UserForm from './components/pages/users/UserForm';
import ErrorPage from './components/common/ErrorPage';
import SubscriptionForm from './components/pages/platforms/SubscriptionForm';
import ScreenDetail from './components/pages/screens/ScreenDetail';
import ThemeForm from './components/pages/theme/ThemeForm';
import Profile from './components/pages/profile/Profile';
import IntegrationForm from './components/pages/platforms/IntegrationForm';
import Tags from './components/pages/tags/Tags';
import { RequestErrorCode } from './types';
import { Route, routes } from './types/routes';
import { strings } from './utils/strings';
import { ScreenFeedDetail } from './components/pages/screens/ScreenFeedDetail';
import { ScreenFeedForm } from './components/pages/screens/ScreenFeedForm';
import PublishingStatus from './components/pages/publishing/PublishingStatus';
import PublishingStatusDetail from './components/pages/publishing/PublishingStatusDetail';
import ForbidProductionEdit from './components/common/ForbidProductionEdit';
import { CheckExistingApp } from './components/common/CheckExistingApp';

/*
 * Note: Some crumbs don't have a `to` property because they will always be
 * the final crumb and therefore not clickable.
 */

const router = createBrowserRouter([
  {
    path: Route.Login,
    element: <Login />,
  },
  {
    path: Route.Register,
    element: <Register />,
  },
  {
    path: Route.Reset,
    element: <CreateNewPassword />,
  },
  {
    path: Route.ForgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: Route.Root,
    element: (
      <RequiresAuthentication>
        <Outlet />
      </RequiresAuthentication>
    ),
    children: [
      {
        path: '',
        errorElement: <ErrorPage />,
        element: (
          <ContextsProvider>
            <Navigation />
            {/* this outlet renders the appropriate children from below */}
            <Outlet />
          </ContextsProvider>
        ),
        children: [
          {
            // landing page will redirect, dependent on user role
            path: '',
            element: (
              <CheckExistingApp>
                <Landing />,
              </CheckExistingApp>
            ),
          },
          {
            path: Route.Accounts,
            handle: {
              crumb: () => ({
                label: strings.accounts.accounts,
                to: routes.accounts,
              }),
            },
            element: (
              <SystemAdminOnly>
                <Outlet />
              </SystemAdminOnly>
            ),
            children: [
              { path: '', element: <Accounts /> },
              { path: Route.Create, element: <AccountForm type="create" /> },
              {
                path: ':accountId',
                handle: {
                  crumb: (names: Record<string, string>) => ({
                    label: names.account,
                  }),
                },
                children: [
                  { path: '', element: <Navigate to={Route.Apps} replace /> },
                  { path: Route.Edit, element: <AccountForm type="edit" /> },
                  {
                    path: Route.Apps,
                    children: [
                      // detail and editing are outside the sys-admin-only 'accounts' route
                      { path: '', element: <Apps /> },
                      {
                        path: Route.Create,
                        element: <AppForm formType="create" />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: Route.Apps,
            children: [
              {
                path: '',
                element: <Navigate to={routes.root} replace />,
              },
              {
                path: ':appId',
                element: (
                  <WaitForApp>
                    <Outlet />
                  </WaitForApp>
                ),
                children: [
                  // viewing all apps and creating apps are in the sys-admin-only 'accounts' route
                  {
                    path: '',
                    handle: {
                      // some repeated code here, because app detail breadcrumb is unique
                      crumb: (
                        names: Record<string, string>,
                        ids: Record<string, string>,
                      ) => [
                        { label: 'Accounts', to: routes.accounts },
                        {
                          label: names.account,
                          to: routes.accountDetail({ accountId: ids.account }),
                        },
                        { label: names.app },
                      ],
                    },
                    element: <AppDetail />,
                  },
                  {
                    path: Route.Edit,
                    element: (
                      <SystemAdminOnly>
                        <AppForm formType="edit" />
                      </SystemAdminOnly>
                    ),
                  },
                  {
                    path: Route.Feeds,
                    handle: {
                      crumb: (
                        _names: Record<string, string>,
                        ids: Record<string, string>,
                      ) => ({
                        label: 'Feeds',
                        to: routes.appFeeds({ appId: ids.app }),
                      }),
                    },
                    children: [
                      { path: '', element: <Feeds /> },
                      {
                        path: Route.Create,
                        element: (
                          <ForbidProductionEdit>
                            <FeedForm formType="create" />
                          </ForbidProductionEdit>
                        ),
                      },
                      {
                        path: ':feedId',
                        handle: {
                          crumb: () => ({ label: strings.feeds.feedDetail }),
                        },
                        element: <FeedDetail />,
                      },
                      {
                        path: `:feedId/${Route.Edit}`,
                        element: (
                          <ForbidProductionEdit>
                            <FeedForm formType="edit" />
                          </ForbidProductionEdit>
                        ),
                      },
                    ],
                  },
                  {
                    path: Route.Screens,
                    handle: {
                      crumb: (
                        _names: Record<string, string>,
                        ids: Record<string, string>,
                      ) => ({
                        label: strings.screens.screens,
                        to: routes.appScreens({ appId: ids.app }),
                      }),
                    },
                    children: [
                      { path: '', element: <Screens /> },
                      {
                        path: Route.Create,
                        element: (
                          <ForbidProductionEdit>
                            <ScreenForm formType="create" />
                          </ForbidProductionEdit>
                        ),
                      },
                      {
                        path: ':screenId',
                        handle: {
                          crumb: (
                            _names: Record<string, string>,
                            ids: Record<string, string>,
                          ) => ({
                            label: strings.screens.screenDetail,
                            to: routes.appScreenDetail({
                              appId: ids.app,
                              screenId: ids.screen,
                            }),
                          }),
                        },
                        children: [
                          {
                            path: '',
                            element: <ScreenDetail />,
                          },
                          {
                            path: Route.ScreenFeed,
                            handle: {
                              crumb: () => ({
                                label: strings.screenFeed.detail,
                              }),
                            },
                            children: [
                              {
                                path: '',
                                element: <Navigate to={'..'} relative="path" />,
                              },
                              {
                                path: ':feedId',
                                element: <ScreenFeedDetail />,
                              },
                              {
                                path: ':feedId/edit',
                                element: (
                                  <ForbidProductionEdit>
                                    <ScreenFeedForm />
                                  </ForbidProductionEdit>
                                ),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: `:screenId/${Route.Edit}`,
                        element: (
                          <ForbidProductionEdit>
                            <ScreenForm formType="edit" />
                          </ForbidProductionEdit>
                        ),
                      },
                    ],
                  },
                  {
                    path: Route.Theme,
                    element: <ThemeForm />,
                  },
                  {
                    path: Route.Tags,
                    children: [
                      { path: '', element: <Tags /> },
                      { path: Route.Badges, element: <Tags /> },
                      { path: Route.TuneIn, element: <Tags /> },
                      // todo v1.1: icons
                      // { path: 'icons', element: <>Icons (WIP)</> },
                    ],
                  },
                  {
                    path: Route.PublishStatus,
                    children: [
                      {
                        path: '',
                        element: <PublishingStatus />,
                      },
                      {
                        path: ':statusId',
                        handle: {
                          crumb: (
                            _names: Record<string, string>,
                            ids: Record<string, string>,
                          ) => [
                            {
                              label: 'App Publishing Status',
                              to: routes.appStatus({ appId: ids.app }),
                            },
                            {
                              label: 'App Publishing Status Detail',
                            },
                          ],
                        },
                        element: <PublishingStatusDetail />,
                      },
                    ],
                  },
                  {
                    path: Route.Platforms,
                    handle: {
                      crumb: (
                        _names: Record<string, string>,
                        ids: Record<string, string>,
                      ) => ({
                        label: strings.platforms.platforms,
                        to: routes.appPlatforms({ appId: ids.app }),
                      }),
                    },
                    children: [
                      { path: '', element: <Platforms /> },
                      {
                        path: ':platformString',
                        handle: {
                          crumb: (names: Record<string, string>) => ({
                            label: names.platform,
                          }),
                        },
                        children: [
                          { path: '', element: <Platform /> },
                          { path: Route.Edit, element: <PlatformConfigForm /> },
                          {
                            path: Route.Subscriptions,
                            children: [
                              { path: '', element: <Platform /> },
                              {
                                path: Route.Create,
                                element: (
                                  <ForbidProductionEdit>
                                    <SubscriptionForm formType="create" />
                                  </ForbidProductionEdit>
                                ),
                              },
                              {
                                path: ':subscriptionId',
                                // there is no subscription product detail; remove the id from the url
                                element: <Navigate to=".." relative="path" />,
                              },
                              {
                                path: `:subscriptionId/${Route.Edit}`,
                                element: (
                                  <ForbidProductionEdit>
                                    <SubscriptionForm formType="edit" />
                                  </ForbidProductionEdit>
                                ),
                              },
                            ],
                          },
                          {
                            path: Route.NavBar,
                            children: [
                              { path: '', element: <Platform /> },
                              {
                                path: Route.Create,
                                element: (
                                  <ForbidProductionEdit>
                                    <NavBarItemForm formType="create" />
                                  </ForbidProductionEdit>
                                ),
                              },
                              {
                                path: ':navBarId',
                                // there is no nav bar item detail; remove the id from the url
                                element: <Navigate to=".." relative="path" />,
                              },
                              {
                                path: `:navBarId/${Route.Edit}`,
                                element: (
                                  <ForbidProductionEdit>
                                    <NavBarItemForm formType="edit" />
                                  </ForbidProductionEdit>
                                ),
                              },
                            ],
                          },
                          {
                            path: Route.Integrations,
                            children: [
                              { path: '', element: <Platform /> },
                              {
                                path: Route.Create,
                                element: (
                                  <SystemAdminOnly>
                                    <ForbidProductionEdit>
                                      <IntegrationForm formType="create" />
                                    </ForbidProductionEdit>
                                  </SystemAdminOnly>
                                ),
                              },
                              {
                                path: ':integrationId',
                                // there is no integration detail; remove the id from the url
                                element: <Navigate to=".." relative="path" />,
                              },
                              {
                                path: `:integrationId/${Route.Edit}`,
                                element: (
                                  <SystemAdminOnly>
                                    <ForbidProductionEdit>
                                      <IntegrationForm formType="edit" />
                                    </ForbidProductionEdit>
                                  </SystemAdminOnly>
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: Route.Users,
            handle: {
              crumb: () => ({
                label: strings.users.users,
                to: routes.users,
              }),
            },
            children: [
              { path: '', element: <Users /> },
              { path: Route.Create, element: <UserForm formType="create" /> },
              {
                path: ':username',
                handle: {
                  crumb: () => ({ label: strings.users.detail }),
                },
                element: <UserDetail />,
              },
              {
                path: `:username/${Route.Edit}`,
                element: <UserForm formType="edit" />,
              },
            ],
          },
          { path: Route.Profile, element: <Profile /> },
        ],
      },
    ],
  },
  {
    path: Route.Error,
    element: <ErrorPage code={RequestErrorCode.NOT_FOUND} />,
  },
]);

export default router;
